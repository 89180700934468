<!-- editpost.vue -->

<template>
    <div class="postmodal" :class="{ open: isPostModalOpen }">
        <div class="modalbox">
            <button @click="closePostModal" class="backbtn"><img src="../../assets/backbtn.png" alt="" class="smallicon"></button>
            <div class="modalbox--header">
                <div v-if="isDraftsListPage" class="savedraftmode">
                    <label for="customDate"></label>
                    <input type="date" id="customDate" v-model="todays" @input="updateToday">
                </div>
                <div v-else class="editmode">
                    <span>{{ todays }}</span>
                </div>

                <button v-if="isDraftsListPage" @click="Savedraft">
                    下書き保存
                </button>
            </div>
            <div class="modalbox--index">
                <div>
                    タイトル
                    <span>{{ title.length }}/64</span>
                    <b>必須</b>
                </div>
                <input type="text" v-model="title" @input="updateCount('title')" class="modalbox--text" placeholder="タイトルを書く。">
            </div>

            <div class="tags">
                <div class="modalbox--index" v-for="(tag, index) in tags" :key="index">
                    <div>
                        タグ
                        <span>{{ tag.length }}/16</span>
                    </div>
                    <button @click="removeTag(index)" class="remtag">
                        <img src="../../assets/backbtn.png" alt="" class="smallicon">
                    </button>
                    <input type="text" v-model="tags[index]" @input="updateCount('tags', index)" class="modalbox--text" placeholder="タグを書く">
                </div>
                <button @click="addTagInput" class="addtag" :class="{ 'hidden': tags.length >= 16 }">
                    タグを追加
                </button>
            </div>

            <div class="modalbox--index">
                <div>
                    前書き
                    <span>{{ lead.length }}/128</span>
                </div>
                <!-- <input type="text" v-model="lead" @input="updateCount('lead')" class="modalbox--lead"> -->
                <textarea v-model="lead" @input="updateCount('lead')" name="" id="" cols="30" rows="5" class="modalbox--lead" placeholder="前書きを書く。"></textarea>
            </div>
            <div class="modalbox--img modalbox--index">
                <div>
                    画像選択
                    <span>{{ selectedImages.length + images.length }}/4</span>
                </div>
                <div class="images">
                    <input class="image" :class="{ 'hidden': (selectedImages.length + images.length) >= 4 }" type="file" @change="handleImageSelection" accept="image/*" aria-label="Choose File" aria-hidden="true">
                    <div class="modalbox--imglist modalbox--index" v-if="images.length > 0 || selectedImages.length > 0">
                        <ul>
                            <li v-for="(image, index) in images" :key="index">
                                <img :src="image" alt="Selected Image" @touchend="showDelImgModal(index, 'edit')">
                                <button @click="removeImage(index, 'edit')"></button>
                            </li>
                            <li v-for="(image, index) in selectedImages" :key="index">
                                <img :src="getObjectURL(image)" alt="Selected Image" @touchend="showDelImgModal(index, 'add')">
                                <button @click="removeImage(index, 'add')"></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modalbox--index">
                <div>
                    本文
                    <span>{{ body.length }}/4096</span>
                </div>
                <textarea v-model="body" @input="updateCount('body')" name="" id="" cols="30" rows="8" class="modalbox--textarea" placeholder="本文を書く。"></textarea>
            </div>
            <div class="modalbox--index modalbox--postbtn">
                <button @click="saveOrUpdatePost" class="postbtn" :class="{ disabled: isPosting }" :disabled="isPosting">
                    <div class="postText"><span v-if="isPosting" class="loading-overlay"><i class="fa fa-spinner fa-pulse fa-3x fa-fw loading-image"></i></span>投 稿</div>
                    <div class="postContents">タグ解放 {{ tagsCount }}</div>
                    <div class="postContents"><img src="../../assets/lxinpost.png" alt="" class="smallicon">-{{ needLx }}</div>
                </button>
            </div>
        </div>
    </div>

    <div class="MessageModal" v-if="isBackMessageModalOpen" @click.self="closeBackMessageModal">
        <div class="back_MessageModal_Body">
            <p>記事を下書き保存せず削除しますか？</p>
            <button class="btn_Modal" @click="DeletePost">削除</button>
            <button class="btn_Modal" @click="Savedraft">保存</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isDeleteMessageModalOpen" @click.self="closeDeleteMessageModal">
        <div class="back_MessageModal_Body">
            <p>入力した内容が削除されます。よろしいですか？</p>
            <button class="btn_Modal" @click="DeletePost">はい</button>
            <button class="btn_Modal" @click="closeDeleteMessageModal">いいえ</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isNotLxMsgModalOpen" @click.self="closeNotLxMsgModal">
        <div class="NotLxMsgModal_Body">
            <div class="closeNotLxMsgbtn"> 
                <button @click="closeNotLxMsgModal">
                    <img src="../../assets/backbtn.png" alt="" class="smallicon">
                </button>
            </div>
            <p>投稿に必要なルクスが不足しています。</p>
            <button class="btn_Modal" @click="showChargeLx"><img src="../../assets/lxinpost.png" alt="" class="smallicon">チャージ</button>
            <button v-if="isDraftsListPage" class="btn_Modal" @click="Savedraft">下書き保存</button>
            <button v-else class="btn_Modal" @click="DeletePost">削除</button>
        </div>
    </div>

     <!-- chargemodal コンポーネントの追加 -->
     <chargemodal :modalOpen="isChargeModalOpen" @closeChargeModal="closeChargeModal"></chargemodal>

    <div class="MessageModal" v-if="isMessageModalOpen" @click.self="closeMessageModal(closeMode)">
        <div class="MessageModal_Body">
            <p>{{ massage }}</p>
            <button class="btn_Modal" @click="closeMessageModal(closeMode)">OK</button>
        </div>
    </div>

    <div class="MessageModal" v-if="isDelImgModalOpen" @click.self="closeDelImgModal">
        <div class="back_MessageModal_Body">
            <p>画像を消去しますか？</p>
            <button class="btn_Modal" @click="PostDelImg(DelImg, DelImgMode)">はい</button>
            <button class="btn_Modal" @click="closeDelImgModal">いいえ</button>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed } from 'vue';
import { collection, deleteDoc, doc, setDoc, getDoc, runTransaction } from 'firebase/firestore';
import { auth, db } from '../../main';
import { ref as storageRef, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useRoute } from 'vue-router';
import chargemodal from '../chargeModal/chargeModal.vue';

export default {
    components: {
        chargemodal,
    },
    props: {
        isPostModalOpen: Boolean,
        whisper: Object,
    },
    setup(props, { emit }) {
        const currentUser = ref(null);
        const postID = ref('');
        const title = ref('');
        const lead = ref('');
        const body = ref('');
        const setdays = ref('');
        const todays = ref('');
        const images = ref([]);
        const selectedImages = ref([]);
        const tags = ref(['']);
        const initTagsCount = ref(0);
        const tagsCount = computed(() => tags.value.length);
        const needLx = computed(() => {
            let tagsLx = 0;
            //タグ数が1つ以上の場合、タグの消費ルクスを算出
            if (tagsCount.value > 1) {
                // 登録されているタグ解放数より、タグ数が多い場合
                if (initTagsCount.value < tags.value.length) {
                    let excessTags = 0;
                    if (initTagsCount.value <= 0) {
                        // 登録されているタグ解放数が0以下の場合
                        excessTags = tags.value.length - 1;
                    } else {
                        // 登録されているタグ解放数が0以上の場合、差分計算
                        let tagsCountValue = initTagsCount.value - 1;
                        let actualTagsCount = tags.value.length - 1;

                        excessTags = actualTagsCount - tagsCountValue;
                    }
                    tagsLx = excessTags * 20;
                }
            }

            let postLx = route.path === '/draftslist' ? 300 : 50; // 下書きの編集の場合、消費ルクスは300
            return tagsLx + postLx;
        });

        const isBackMessageModalOpen = ref(false);
        const isMessageModalOpen = ref(false);
        const isDeleteMessageModalOpen = ref(false);
        const isNotLxMsgModalOpen = ref(false);
        const isChargeModalOpen = ref(false);
        const massage = ref('');
        const closeMode = ref('');
        const DelImg = ref('');
        const DelImgMode = ref('');
        const isDelImgModalOpen = ref(false);
        const isPosting = ref(false);

        const route = useRoute();
        const isDraftsListPage = computed(() => route.path === '/draftslist');

        // setdaysを加工するとtodaysが切り替わる。ので切り替わる前のデータを保持して代入するための値
        const days = ref('');

        auth.onAuthStateChanged(user => {
            currentUser.value = user;
        });

        // whisperオブジェクトの変更を監視してログに表示する
        watch(() => props.whisper, (newVal, oldVal) => {
            // whisperオブジェクトから必要なプロパティを取得して各変数に代入する
            if (newVal) {
                days.value = newVal.todays || '';
                postID.value = newVal.id || '';
                title.value = newVal.title || '';
                lead.value = newVal.lead || '';
                body.value = newVal.body || '';
                setdays.value = newVal.setdays || '';
                todays.value = newVal.todays || '';
                images.value = newVal.imageUrls || [];
                tags.value = newVal.tags || [];
                initTagsCount.value = newVal.tagsCount || 0;

                setTodaysDate();
            }
        });

        // 今日の日付を設定する関数
        const setTodaysDate = () => {
            const today = new Date();

            //console.log('todayや', today)

            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');

            if (isDraftsListPage.value) {
                setdays.value = `${year}-${month}-${day}`;
                //console.log('setdaysや', setdays.value)
            } else {
                todays.value = `${year}-${month}-${day}`;
                //console.log('todaysや', todays.value)
            }
        };


        const updateToday = () => {

            // Date オブジェクトを作成
            const selectedDate = new Date(todays.value);

            //console.log('選択された日付:todays', todays.value);

            // 0時0分0秒に設定
            selectedDate.setHours(0, 0, 0, 0);

            // 年、月、日を取得
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1、2桁表示
            const day = String(selectedDate.getDate()).padStart(2, '0'); // 2桁表示

            // setdays には指定の0000-00-00フォーマットで文字列を格納
            const setday = `${year}-${month}-${day}`;
            //console.log('setday:', setdays);
            setdays.value = setday;
            //console.log('setdays:', setdays.value);
        };

        // 削除・保存確認メッセージDLGの表示
        const showBackMessageModal = () => {
            isBackMessageModalOpen.value = true;
        };
        // 削除・保存確認メッセージDLGの非表示
        const closeBackMessageModal = () => {
            isBackMessageModalOpen.value = false;
        };
        // 削除確認メッセージDLGの表示
        const showDeleteMessageModal = () => {
            isDeleteMessageModalOpen.value = true;
        };
        
        // 削除確認メッセージDLGの非表示
        const closeDeleteMessageModal = () => {
            isDeleteMessageModalOpen.value = false;
        };
        // 確認メッセージDLGの表示
        const showMessageModal = (text, mode) => {
            isBackMessageModalOpen.value = false;
            isDeleteMessageModalOpen.value = false;
            massage.value = text;
            closeMode.value = mode;
            isMessageModalOpen.value = true;
        };
        // 確認メッセージDLGの非表示
        const closeMessageModal = (closeMode) => {
            isMessageModalOpen.value = false;
            if (closeMode == 'closePostModal') {
                emit('update:isPostModalOpen', false);
                window.location.reload();
            }
            if (closeMode == 'closeAddPostModal') {
                emit('update:isPostModalOpen', false);
                window.location.reload();
                //$router.go({ path: $route.currentRoute.value.path, force: true });
            }
        };

        //記事の削除処理
        const DeletePost = () => {
            closeBackMessageModal();
            closeChargeModal();
            showMessageModal('削除しました', 'closePostModal');
        };

        // ルクス不足メッセージDLGの表示
        const showNotLxMsgModal = () => {
            isNotLxMsgModalOpen.value = true;
        };
        // ルクス不足メッセージDLGの非表示
        const closeNotLxMsgModal = () => {
            isNotLxMsgModalOpen.value = false;
        };
        const showChargeLx = () => {
            closeNotLxMsgModal();
            isChargeModalOpen.value  = true;
        };
        const closeChargeModal = () => {
            isChargeModalOpen.value = false;
        };

        // 画像削除確認メッセージDLGの表示
        const showDelImgModal = (index, field) => {
            DelImg.value = index;
            DelImgMode.value = field;
            isDelImgModalOpen.value = true;
        };
        // 確認メッセージからの画像削除処理
        const PostDelImg = (DelImg, DelImgMode) => {
            removeImage(DelImg, DelImgMode);
            isDelImgModalOpen.value = false;
        };
        // 画像削除確認メッセージDLGの非表示
        const closeDelImgModal = () => {
            isDelImgModalOpen.value = false;
        };

        // 「✕」ボタン押下時
        const closePostModal = () => {
            if (isDraftsListPage.value) {
                if (title.value.trim() !== '' || tags.value.some(tag => tag.trim() !== '') || 
                    lead.value.trim() !== '' || selectedImages.value.length > 0 || 
                    body.value.trim() !== '') {
                    // 本文、タグ、前書き、本文のいずれかが入力されている、または画像が選択されている場合、削除・保存確認メッセージを表示
                    showBackMessageModal();
                } else{
                    showDeleteMessageModal();
                }
            } else {
                showDeleteMessageModal();
            }
        };

        const closeModal = () => {
            //emit('update:isPostModalOpen', false);
            title.value = '';
            lead.value = '';
            body.value = '';
            selectedImages.value = [];

            //window.location.reload();
        };

        const updateCount = (field, index) => {
            // フィールドごとに文字数を更新
            if (field === 'title' && title.value.length > 64) {
                //タイトル、最大入力文字数は64
                title.value = title.value.slice(0, 64);
            }
            if (field === 'tags' && tags.value[index].length > 16) {
                //タグ、最大入力文字数は16
                tags.value[index] = tags.value[index].slice(0, 16);
            }
            if (field === 'lead' && lead.value.length > 128) {
                //前書き、最大入力文字数は128
                lead.value = lead.value.slice(0, 128);
            }
            if (field === 'body' && body.value.length > 4096) {
                //本文、最大入力文字数は128
                body.value = body.value.slice(0, 4096);
            }
        };

        const handleImageSelection = (event) => {
            //console.log('selectedImages入れる', selectedImages.value);
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                if (selectedImages.value.length < 4) {
                    selectedImages.value.push(files[i]);
                }
            }
        };

        const removeImage = (index, field) => {
            if (field === 'edit') {
                images.value.splice(index, 1);
            }
            if (field === 'add') {
                selectedImages.value.splice(index, 1);
            }
        };

        const getObjectURL = (file) => {
            if (!file) return '';
            return window.URL.createObjectURL(file);
        };

        const saveOrUpdatePost = async () => {
            if (isPosting.value) {
                // 既に投稿処理中の場合は何もしない
                return;
            }
            isPosting.value = true; // 投稿処理を開始

            try {
                if (title.value === '') {
                    showMessageModal('タイトルを入力してください。', 'closeMessageModal');
                    return;
                }

                const imageUrls = [];

                // 画像のアップロードを並行処理する
                if (selectedImages.value.length > 0) {
                    const storage = getStorage();

                    // アップロードのプロミスを作成
                    const uploadPromises = selectedImages.value.map((image) => {
                        const storageReference = storageRef(storage, `images/${currentUser.value.uid}/${Date.now()}_${image.name}`);
                        return uploadBytes(storageReference, image).then(() => getDownloadURL(storageReference));
                    });

                    // すべてのアップロードを実行
                    const downloadURLs = await Promise.all(uploadPromises);

                    // ダウンロードURLを追加
                    imageUrls.push(...downloadURLs);
                }

                // 既存の画像も追加
                imageUrls.push(...images.value);

                const edittags = [...tags.value];

                // tagsCount の計算
                let maxTagsCount = initTagsCount.value;
                if (tags.value.length > initTagsCount.value) {
                    maxTagsCount = tags.value.length;
                }

                // ユーザーリファレンスの取得
                const userRef = doc(db, 'users', currentUser.value.uid);

                // トランザクションの実行
                await runTransaction(db, async (transaction) => {
                    const userDoc = await transaction.get(userRef);
                    if (!userDoc.exists()) {
                        throw new Error('USER NOT FOUND');
                    }

                    const userData = userDoc.data();
                    const currentPoints = userData.point || 0;

                    if (currentPoints < needLx.value) {
                        throw new Error('NOT ENOUGH LX');
                    }

                    // ポイントの減算
                    const newPoints = currentPoints - needLx.value;
                    transaction.update(userRef, { point: newPoints });

                    // 投稿データの準備
                    const postData = {
                        title: title.value,
                        lead: lead.value,
                        body: body.value,
                        todays: todays.value,
                        setdays: setdays.value,
                        imageUrls: imageUrls,
                        tags: edittags,
                        tagsCount: maxTagsCount,
                        point: 0,
                        originid: userData.originid,
                    };

                    const whispersRef = collection(db, 'whispers');

                    if (postID.value) {
                        const postRef = doc(whispersRef, postID.value);
                        transaction.set(postRef, postData);
                    } else {
                        const newPostRef = doc(whispersRef);
                        transaction.set(newPostRef, postData);
                        postID.value = newPostRef.id;
                    }

                    // 下書きの削除
                    const draftRef = doc(db, 'users', currentUser.value.uid, 'savedraft', postID.value);
                    transaction.delete(draftRef);
                });

                showMessageModal('記事が投稿されました。', 'closeAddPostModal');
                closeModal();
            } catch (error) {
                if (error.message === 'NOT ENOUGH LX') {
                    //ルクスが不足し投稿できない場合
                    showNotLxMsgModal();
                } else {
                    // console.log('投稿の保存/更新エラー:', error);
                    showMessageModal('投稿に失敗しました。\nサーバーに問題が発生している可能性があります。\nしばらくしてから再試行してください。', 'closeMessageModal');
                }
            } finally {
                isPosting.value = false; // 処理終了後にボタンを再度有効化
            }
        };

        const addTagInput = async () => {
            tags.value.push(''); // タグ入力欄を追加する
        };

        const removeTag = async (index) => {
            tags.value.splice(index, 1); // 指定されたインデックスのタグを削除する
        };

        const Savedraft = async () => {
            closeNotLxMsgModal();
            try {
                // 本文、タグ、前書き、本文が入力されていない、かつ画像が選択されていない場合
                if (title.value.trim() == '' && tags.value.some(tag => tag.trim() == '') && 
                lead.value.trim() == '' && selectedImages.value.length == 0 && 
                body.value.trim() == '') {
                    showMessageModal('保存する記事がありません。', 'closeMessageModal');
                    return;
                }

                // Storageオブジェクトを取得
                const storage = getStorage();

                // ユーザーのドキュメントを取得
                const userRef = doc(db, 'users', currentUser.value.uid);
                const userDoc = await getDoc(userRef);

                // 選択された画像のダウンロードURLを取得するための配列を作成
                const imageUrls = [];
                for (let i = 0; i < selectedImages.value.length; i++) {
                    const image = selectedImages.value[i];
                    const storageRef = ref(storage, `images/${currentUser.value.uid}/${Date.now()}_${image.name}`);
                    await uploadBytes(storageRef, image);

                    // 画像のダウンロードURLを取得して配列に追加
                    const downloadURL = await getDownloadURL(storageRef);
                    imageUrls.push(downloadURL);
                }

                // Firestoreに保存するデータを作成
                const postData = {
                    title: title.value,
                    tags: tags.value,
                    lead: lead.value,
                    body: body.value,
                    todays: todays.value,
                    setdays: setdays.value,
                    imageUrls: imageUrls,
                    point: 0,
                    originid: userDoc.data().originid, // ユーザーのidを取得
                };

                // Firestoreにデータを保存
                const postRef = doc(collection(db, 'users', currentUser.value.uid, 'savedraft'));
                await setDoc(postRef, postData);

                // 投稿後の処理
                const userId = auth.currentUser.uid;
                await deleteDoc(doc(db, 'users', userId, 'savedraft', postID.value));

                //closeModal();
                //console.log('下書きに保存しました');
                showMessageModal('記事を下書き保存しました。', 'closeAddPostModal');

            } catch (error) {
                //console.log('投稿エラー:', error.message);
                // エラー発生時、エラーメッセージを表示
                showMessageModal('記事の下書き保存に失敗しました。\nサーバーに問題が発生している可能性があります。\nしばらくしてから再試行してください。', 'closeMessageModal');
            }
        };


        return {
            Savedraft,
            currentUser,
            title,
            lead,
            body,
            todays,
            setdays,
            images,
            selectedImages,
            closePostModal,
            closeModal,
            removeImage,
            getObjectURL,
            handleImageSelection,
            saveOrUpdatePost,
            updateCount,
            updateToday,
            days,
            isDraftsListPage,
            removeTag,
            addTagInput,
            tags,
            initTagsCount,
            tagsCount,
            needLx,

            showBackMessageModal,
            closeBackMessageModal,
            DeletePost,
            isBackMessageModalOpen,
            isMessageModalOpen,
            isDeleteMessageModalOpen,
            showDeleteMessageModal,
            closeDeleteMessageModal,
            showMessageModal,
            closeMessageModal,
            massage,
            closeMode,
            isNotLxMsgModalOpen,
            showNotLxMsgModal,
            closeNotLxMsgModal,
            isChargeModalOpen,
            showChargeLx,
            closeChargeModal,
            isPosting,

            DelImg,
            DelImgMode,
            isDelImgModalOpen,
            showDelImgModal,
            PostDelImg,
            closeDelImgModal
        };
    },
};
</script>





<style lang="scss" scoped>
    .postmodal {
        display: none;
    }

    .tags{
        .modalbox--index{
            .modalbox--text{
                // margin-left: 32px;
                width: 32% !important;
                @media (max-width: 599px) {
                    width: 92% !important;
                }

            }
        }
        .addtag{
            margin: 0 20px;
            font-size: 15px;
            background: #C1C1C1;
            color: #323232;
            // font-size: 20px;
            padding: 5px 15px;
            border-radius: 50px;
            font-weight: 600;
        }
        .remtag{
            position: relative;
            // top: 26px;
        }
        button{
            .smallicon{
                width: 26px;
            }
        }
    }

    .postmodal.open {
        display: block;
        position: absolute;
        z-index: 10000;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        .modalbox{
            // height: 90rem;
            // height: auto;
            width: 80%;
            height: 95%;
            // margin: 10px;
            overflow-x: scroll;
            // overflow-y: scroll;
            overflow-x: auto;
            color: #fff;
            background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
            border-radius: 10px ;
            // border: 1px solid #fff;
            @media screen and (max-width: 1000px) {
                width: 95%;
                height: 95%;
                overflow-x: scroll;
                // overflow-y: scroll;
            }
            &--header{
                display: flex;
                align-items: center;
                font-size: 50px;
                font-weight: 700;
                justify-content: space-between;
                margin: 20px;
                @media screen and (max-width: 599px) {
                    font-size: 38px;
                    align-items: flex-end;
                    flex-direction: column-reverse;
                }
                button{
                    background: #C1C1C1;
                    color: #323232;
                    font-size:15px;
                    padding:5px 15px;
                    border-radius: 50px;
                    font-weight: 600;
                }
            }
            &--imglist{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                margin: 0 20px !important;
                ul{
                    width: 100%;
                    // height: 25rem;
                    // margin: 5px;
                    // display: flex;
                    // align-items: center;
                    // justify-content: flex-start;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-template-rows: auto auto;
                    grid-gap: 10px;
                    li{
                        width: 100%;
                        height: 25rem;
                        position: relative;
                        margin: 0 15px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                        button{
                            display: none;
                            position: absolute;
                            background: url("../../assets/X.png") no-repeat center center; /* 画像のパスと表示設定 */
                            background-size: contain;
                            width: 11rem;
                            height: 11rem;
                            // font-size: 11rem;
                            border-radius: 50%;
                            background-color: #C1C1C1;
                            right: 1.5rem;
                            top: 1.5rem;
                            align-items: center;
                            justify-content: center;
                            color: #323232;
                        }
                    }
                    li:hover button {
                       display: flex;
                    }
                }
            }
            .backbtn{
                position: relative;
                left: 95%;
                top: 15px;
                width: 30px;
                // height: 5px;
                @media screen and (max-width: 599px) {
                    left: 86%;
                    top: 2px;
                }
                img{
                    cursor: pointer;
                    width: 30px;
                }
            }
            &--index{
                margin: 20px;
                font-size: 15px;
                b{
                    font-size: 12px;
                    color: #ea0f29;
                    margin-left:10px;
                    font-weight: 400;
                }
            }
            &--text{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }

            &--lead{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }
            &--textarea{
                width: 100%;
                background-color: #323232;
                padding: 5px;
            }
            &--postbtn{
                // width: 100%;
                text-align: center;
            }
            .postbtn{
                width: 150px;
                padding: 10px 0;
                text-align: center;
                border-radius: 10px;
                background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
                .postText{
                    font-size: 30px;
                    font-weight: bold;
                    margin-bottom: 7px;
                }
                .postContents{
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 25px;
                    font-size: 18px;
                    img{
                        width: 15px;
                        margin-right: 10px;
                    }
                }
                .loading-overlay {
                    color: #00A8A8;
                    font-size: 10px;
                }
            }
            .postbtn.disabled {
                height: 111px;
                color: #aaa9a9;
                background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #9b9898;
                cursor: not-allowed;
            }
            // スクロールバーを非表示にする
            &::-webkit-scrollbar {
                display: none;
            }
            // スクロール可能な状態でスクロールバーを表示する
            &:hover,
            &:active,
            &:focus {
                &::-webkit-scrollbar {
                display: block;
                width: 0px; // スクロールバーの幅
                }
                &::-webkit-scrollbar-track {
                background-color: transparent;
                }
                &::-webkit-scrollbar-thumb {
                background-color: #828f85; // スクロールバーの色
                border-radius: 5px;
                }
            }
        }
    }

    .modalbox--img{
        width: 96%;
        .images{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
        input.image{
            cursor: pointer;
            width: 25rem;
            height: 30rem;
            background: url("../../assets/imgae.png") no-repeat center center; /* 画像のパスと表示設定 */
            background-size: contain;
            font-size: 0;
        }
        .image::file-selector-button {
            display: none;
        }
        .image::file-selector-button::before {
            content: none;
        }
    }

    .hidden {
        cursor: not-allowed;
        pointer-events: none;
        display: none;
    }

    .tag{
        a{
            font-size: 13px;
            letter-spacing: 0px;
            margin-left: 10px;
        }
    }
    .modalbox--header {
        text-align: left;
        @media (max-width: 599px) {
            width: unset;
        }
        input[type="date"] {
            background-image: url("../../assets/calendar.png"); // カスタムアイコンのパス
            background-repeat: no-repeat;
            background-position: right 10px center; // アイコンの位置を右側に設定
            cursor: pointer; // カーソルをポインターに設定
            -webkit-appearance: none; // ブラウザデフォルトのスタイルを無効化
            appearance: none;
            background-size: 30px; // アイコンのサイズを指定
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
            opacity: 0; // インジケーターを透明にする
            // position: absolute; // インジケーターの位置を調整
            width: 20px; // インジケーターの幅をアイコンの幅に合わせる
            height: 20px; // インジケーターの高さをアイコンの高さに合わせる
            right: 10px; // 右からの位置を指定
            cursor: pointer; // カーソルをポインターに設定
        }
    }


    // 確認メッセージDLG　オーバーレイ
    .MessageModal {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 11000;
    }

    // 記事の削除/保存確認DLG
    .back_MessageModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        z-index: 40;

        p{
            font-size: 18px;
            font-weight: 500;
        }
    }

    // 確認メッセージDLG
    .MessageModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        width: 300px;
        height: 110px;
        z-index: 40;

        p{
            font-size: 18px;
            font-weight: 500;
        }
    }

    // 記事の削除/保存確認DLG、確認メッセージDLG ボタン
    .btn_Modal {
        margin: 20px 8px 0 8px;
        border: none;
        border-radius: 5px;
        width: 100px;
        height: 35px;
        font-size: 16px;
        background: linear-gradient(180deg, #1903031A 3.32%, #4d4d4d 94.3%), #00A8A8;
    }

    //ルクス不足メッセージDLG
    .NotLxMsgModal_Body {
        background-color: #323232;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #00a8a8;
        text-align: center;
        z-index: 40;

        .closeNotLxMsgbtn {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            img{
                cursor: pointer;
                width: 20px;
            }
        }
        p{
            font-size: 18px;
            font-weight: 500;
        }
        .btn_Modal {
            img{
                width: 15px;
                margin-right: 5px;
            }
        }
    }
</style>