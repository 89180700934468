<!-- auth.vue -->
<template>
    <div class="auth">
        <div class="auth--box">
            <div class="auth--box__col col1">
                <img src="../assets/diposty.png" alt="" class="logo-img">
            </div>
            <div class="auth--box__col col2">
                <h1 class="col2--title">あなたの日記が、<br>だれかの旅になる。</h1>
                <p class="col2--catch">今すぐはじめよう。</p>

                <form @submit.prevent="createEmailAccount">
                    <input v-model="email" type="email" placeholder="メールアドレス" required class="btn--login__mailinput" autocomplete="email"/>
                    <div class="input-password">
                        <input v-model="password" :type="passwordFieldType" placeholder="パスワード" required class="btn--login__mailinput" autocomplete="current-password"/>
                        <button type="button" class="toggle-password" @mousedown="togglePassword('password')" @mouseup="togglePassword('password')">
                            <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                    </div>
                    <div class="input-password">
                        <input v-model="re_password" :type="re_passwordFieldType" placeholder="パスワードと同じ文字列を入力してください" required class="btn--login__mailinput" autocomplete="current-password"/>
                        <button type="button" class="toggle-password" @mousedown="togglePassword('re_password')" @mouseup="togglePassword('re_password')">
                            <i :class="passwordFieldType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                        </button>
                    </div>
                    <p v-if="passwordError" class="passErrorText" v-html="passwordError"></p>
                    <div class="passText">
                        <p>パスワードは下記の条件を満たしてください。</p>
                        <p>・6文字以上</p>
                        <p>・1つ以上の大文字のアルファベットを含む</p>
                        <p>・1つ以上の数字を含む</p>
                    </div>

                    <button type="submit" class="btn--login__mailcreation">
                        <img src="../assets/M.png" alt="" class="M-img">
                        メールアドレスで新規登録
                    </button>

                    <div class="text-danger">{{ error }}</div>
                </form>

                <div class="namekey">
                    <div>
                        <p class="namekey--en">Diary</p>
                        <p class="namekey--ja">日記</p>
                    </div>
                    <p class="namekey--en">＋</p>
                    <div>
                        <p class="namekey--en">Post</p>
                        <p class="namekey--ja">投稿</p>
                    </div>
                </div>
                <!-- <img src="../assets/icon.png" alt="" class="icon-img"> -->
                <a href="/"><img src="../assets/iconorigin.png" alt="" class="logo-icon logoicon" ></a>

            </div>
        </div>
    </div>
</template>


<script>
import { signInWithPopup, signOut, GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth'; // 必要なモジュールをインポート
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../main';
import { auth } from '../main';
import { id } from 'date-fns/locale';

// ランダムな英数字20文字の生成関数
function generateRandomId(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
}

export default {
    props: ['currentUser'],

    data() {
        return {
            email: '',
            password: '',
            re_password: '',
            passwordError: '',
            error: "",
            passwordFieldType: 'password', // パスワードフィールドのタイプ
            re_passwordFieldType: 'password' // パスワードフィールドのタイプ
        };
    },

    methods: {
        togglePassword(field) {
            if (field === 'password') {
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            } else if (field === 're_password') {
                this.re_passwordFieldType = this.re_passwordFieldType === 'password' ? 'text' : 'password';
            }
        },

        async createUser(user) {
            const userRef = doc(db, 'users', user.uid);
            const randomId = generateRandomId(20);
            const todayDate = new Date().toISOString().split('T')[0]; // 今日の日付をYYYY-MM-DD形式で取得

            const userData = {
                name: user.displayName,
                photoURL: user.photoURL,
                email: user.email,
                originid: randomId,
                point: 0,
                lastLogin: todayDate,
                password: this.password,
            };

            try {
                await setDoc(userRef, userData, { merge: true });
                // ホームに遷移
                this.$router.push('/');
                localStorage.setItem('authlog', 'true');
            } catch (error) {
                // console.log('ユーザー作成エラー:', error);
            }
        },

        async createEmailAccount() {
            //バリデーションチェック
            if (!this.validatePassword()) {
                return;
            }

            const email = this.email;
            const password = this.password;

            try {
                // Firebase Authenticationで新規アカウントを作成
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);

                // メール認証を送信
                this.sendEmail();
            } catch (e) {
                if (e.code === 'auth/email-already-in-use') {
                    // メールアドレスが既に存在する場合の処理
                    alert('このメールアドレスは既に使用されています。別のメールアドレスを使用してください。');
                    return;
                } else {
                    // エラーが発生した場合、アラートを表示
                    alert("アカウント作成中にエラーが発生しました");
                    this.error = e.message;
                }
            }
        },

        validatePassword() {
            const minLength = /.{6,}/;
            const uppercase = /[A-Z]/;
            const number = /[0-9]/;

            let errors = [];
            if (this.password !== this.re_password) {
                errors.push('※パスワードと確認用の文字列は一致するように入力してください。');
            }
            if (!minLength.test(this.password)) {
                errors.push('※パスワードは6文字以上で入力してください。');
            }
            if (!uppercase.test(this.password)) {
                errors.push('※パスワードには少なくとも1つの英語大文字を含める必要があります。');
            }
            if (!number.test(this.password)) {
                errors.push('※パスワードには少なくとも1つの数字を含める必要があります。');
            }

            if (errors.length > 0) {
                this.passwordError = errors.join('<br>'); // 全てのエラーを改行で繋げて表示
                return false;
            }
            return true;
        },

        async sendEmail() {
            const actionCodeSettings = {
                url: "http://" + location.host + "/auth",
            };
            auth.languageCode = "ja";
            const user = auth.currentUser;
            try {
                await sendEmailVerification(user, actionCodeSettings);
                alert("認証メールを送りました!メールを開いて認証してください");
            } catch (e) {
                console.log(e);
            }
        },
    },
}
</script>




<style lang="scss" scoped>

    .auth{
        width: 100%;
        height: 100dvh;
        overflow: auto;
        padding: 20px 30px;
        min-width: 500px;
        @media screen and (max-width: 599px) {
            min-width: 370px;
        }
        form{
            width: 65%;
            min-width: 300px;
            @media screen and (max-width: 599px) {
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .logo-img{
            @media screen and (max-width: 599px) {
                display: none;
            }
        }
        .icon-img{
            display: none;
            @media screen and (max-width: 599px) {
                display: block;
                position: absolute;
                top: 3%;
                z-index: -1;
                width: 70%;
                opacity: 0.3;
            }
        }
        &--box{
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 599px) {
                // flex-direction: column;
                flex-direction: column-reverse;
            }
            &__col{
                width: 50%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 599px) {
                    height: unset;
                    width: 100%;
                }
            }
            .col2{
                flex-direction: column;
                align-items: flex-start;
                color: white;
                @media screen and (max-width: 599px) {
                    height: unset;
                    width: 100%;
                    align-items: center;
                }
                &--title{
                    font-size: 40px;
                    width: 100%;
                    margin-bottom: 15px;
                    min-width: 500px;
                    @media screen and (max-width: 599px) {
                        bottom: 12%;
                        padding-left: 17px;
                        letter-spacing: -1.5px;
                        line-height: 1.5;
                        min-width: 370px;
                    }
                }
                &--catch{
                    font-size: 25px;
                    margin: 25px 0;
                    @media screen and (max-width: 599px) {
                        position: relative;
                        padding-left: 17px;
                        width: 100%;
                    }
                }
            }
            .btn{
                &--login{
                    &__mailinput{
                        width: 100%;
                        min-width: 290px;
                        background-color: #D9D9D9;
                        color: #000;
                        text-align: start;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 15px;
                        padding-left: 15px;
                        @media screen and (max-width: 599px) {
                            height: 40px;
                        }
                    }
                    &__mailcreation{
                        width: 100%;
                        min-width: 290px;
                        background-color: #0B704B;
                        color: #ffffff;
                        text-align: center;
                        line-height: 45px;
                        border-radius: 30px;
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 12px;
                        .M-img{
                            width: 30px;
                            border-radius: 50%;
                            margin: 0 10px;
                            background: #ffffff;
                            padding: 3px;
                        }
                    }
                }
            }
            .namekey{
                display: flex;
                margin-top: 30px;
                width: 100%;
                justify-content: center;
                @media screen and (max-width: 599px) {
                    top: 12%;
                }    
                &--en{
                    font-size: 60px;
                    @media screen and (max-width: 599px) {
                    }
                }
                &--ja{
                    font-size: 25px;
                    @media screen and (max-width: 599px) {                    
                    }
                }
            }
        }
        .logoicon{
            top: 15%;
            position: absolute;
            transform: translate(-50%, -50%);
            z-index: -1;
            opacity: 0.5;
            width: 220px;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 599px) {
                display: none;
            }
        }
        .input-password {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%; 
        }
        .input-password .btn--login__mailinput {
            padding-right: 40px; /* ボタンのスペースを確保 */
            width: 100%; 
        }
        .toggle-password {
            position: absolute;
            right: 20px;
            top: 45%; 
            transform: translateY(-50%);
            padding-bottom: 10px;
            background: none;
            border: none;
            cursor: pointer;
            color: dimgray;
        }
        .passText{
            text-align: left;
            margin-left: 20px;
            margin-bottom: 15px;
        }
        .passErrorText{
            text-align: left;
            margin-left: 20px;
            margin-bottom: 15px;
            color: #e02f2f;
        }

        /* Edgeのパスワード表示ボタンを非表示 */
        input[type="password"]::-ms-reveal {
            display: none;
        }
        input[type="password"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
        }
    }
</style>