<!-- comment.vue -->
<template>
    <button @click="handleButtonClick"><img src="../../assets/coment.png" alt="" class="smallicon"><span>{{ commentCount }}</span></button>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '../../main';
import { doc, getDoc, updateDoc, arrayUnion, collection, addDoc, getDocs, setDoc } from 'firebase/firestore';
import { auth } from '../../main';

export default {
  props: {
    id: String,
  },
  setup(props) {
    const currentUser = ref(null);
    const originid = ref(localStorage.getItem('originId') || '');
    const isCommentModalOpen = ref(false);
    const articleBody = ref('');
    const commentText = ref('');
    const commentCount = ref(0);
    let commentsCollectionRef = null;
    const generateRandomId = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomId = '';
      for (let i = 0; i < 20; i++) {
          randomId += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return randomId;
    };

    const commentsLength = async () => {
      if (!commentsCollectionRef) {
        //console.log('Firestore コレクションへの参照がありません。');
        return;
      }

      const commentsSnapshot = await getDocs(commentsCollectionRef);
      commentCount.value = commentsSnapshot.size;
    };

    const Modalopen = async () => {
      if (!commentsCollectionRef) {
        //console.log('Firestore コレクションへの参照がありません。');
        return;
      }

      const commentsSnapshot = await getDocs(commentsCollectionRef);
      commentCount.value = commentsSnapshot.size;

      isCommentModalOpen.value = true;
    };

    const Modalclose = () => {
      isCommentModalOpen.value = false;
    };

    const NewComment = async () => {
      if (commentText.value.trim() === '') {
        alert('コメントがありません。');
        Modalclose();
        return;
      }

      try {
        // ランダムなドキュメントIDを生成
        const randomDocId = generateRandomId();

        const articleId = props.id;
        const articleDocRef = doc(db, 'whispers', articleId);
        const commentsCollectionRef = collection(articleDocRef, 'comments');
        const newCommentDocRef = doc(commentsCollectionRef, randomDocId); // newCommentDocRef を初期化

        const userDocRef = doc(db, 'users', currentUser.value.uid);
        const userDocSnap = await getDoc(userDocRef);
        const originidValue = userDocSnap.data().originid;

        const myCommentsCollectionRef = collection(userDocRef, 'mycomments');
        const newMyCommentDocRef = doc(myCommentsCollectionRef, randomDocId);

        // 新しいコメントをサブコレクションに追加
        await setDoc(newCommentDocRef, {
            originid: originidValue,
            text: commentText.value,
            point: 0,
            postid: articleId,
        });
        await setDoc(newMyCommentDocRef, {
            postid: articleId,
        });

        // コメント数を更新
        commentCount.value += 1;

        // コメントを追加した後、commentTextをクリアしてtextareaをリセット
        commentText.value = '';

        // コメントを追加したことを通知
        alert('コメントを追加しました！');
        Modalclose();
      } catch (error) {
        //console.log('コメントの追加中にエラーが発生しました:', error);
      }
    };

    onMounted(async () => {
        auth.onAuthStateChanged(async user => {
            currentUser.value = user;
        });

        // コンポーネントがマウントされたときに Firestore コレクションへの参照を作成
        const articleId = props.id;
        const articleDocRef = doc(db, 'whispers', articleId);
        commentsCollectionRef = collection(articleDocRef, 'comments');

        // 初回のコメント数を取得
        commentsLength();
    });

    const handleButtonClick = () => {
        // ログインしているかどうかを確認
        if (!auth.currentUser) {
            // ログインしていない場合はアラートを表示してログインページに遷移
            if (confirm("ログインしてください")) {
                router.push('/auth'); // '/auth'はログインページのURLに置き換えてください
            }
        } else {
            // ログインしている場合に実行したい処理をここに追加
        }
    };

    return {
      currentUser,
      originid,
      isCommentModalOpen,
      articleBody,
      commentText,
      commentCount,
      commentsLength,
      Modalopen,
      Modalclose,
      NewComment,
      generateRandomId,
      handleButtonClick
    };
  },
};
</script>



<style lang="scss" scoped>
    button{
        display: flex;
        align-items: center;
        justify-content: center;
      .smallicon{
      // width: 30%;
        width: 16px;
      }
        
      span{
        padding-left: 6px;
      }
    }

    .commentModal {
        display: none;
    }

    .commentModal.open {
        display: block;
        position: absolute;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        // background-color: rgba(128, 128, 128, 0.308);
        background-color: #000000ad;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        @media (max-width: 599px) {
          font-size: 10px;
        }
        .commentModalBody{
          background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
          // border: 1px solid #fff;
          border-radius: 10px;
          width: 600px;
        }
    }
    .comment{
      &--content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
      }
      &--textarea{
        width: 100%;
        background-color: #323232;
        padding: 5px;
        line-height: 1.5;
      }
    }
    .postbtn{
      margin-top: 10px;
      width: 25rem;
      height: 10rem;
      text-align: center;
      background: linear-gradient(180deg, #1903031A 3.32%, #323232 94.3%), #00A8A8;
      }
    .backbtn{
      position: relative;
      left: 92%;
      top: -4px;
      width: 30px;
      height: 0px;
      @media (max-width: 599px) {
        left: 89%;
        top: 5px;
        }
      img{
        cursor: pointer;
        width: 30px;
      }
    }

</style>