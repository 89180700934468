<!-- homeview.vue -->

<template>

    <Auth />

</template>

<script>
    import Auth from '@/components/auth.vue';
    import Header from '@/components/mainComponents/Header.vue';
    import FooterNav from '@/components/mainComponents/FooterNav.vue';
    import lord from '@/components/mainComponents/load.vue'
    
    export default {
        components: {
            Auth,
            Header,
            FooterNav,
            lord
        },
        data() {
        return {
            currentUser: null,
        };
        },
    };
</script>