<!-- item.vue -->

<template>
  <div class="home">
    <div class="inner">
      <div class="top">

        <img src="../../assets/icon.png" alt="" class="icon-img">
        <div class="top-calendar">
          <div class="calendar">
            <button class="top--back" @click="getBackDate">
              <img src="../../assets/arowleft.png" alt="">
            </button>
            <label for="datepicker" class="top--datepicker">
              <input type="date" id="datepicker" v-model="todayDate">
            </label>
            <button class="top--next" @click="getNextDate">
              <img src="../../assets/arowright.png" alt="">
            </button>
          </div>
        </div>

        <!-- トリップボタン -->
        <trip-button class="trip" @updateDate="updateTodayDate" />

        <!-- 検索結果画面へ -->
        <button class="searched" @click="prisetSearch">
          <img src="../../assets/searched.png" alt="" class="searched-btn">
        </button>

        <!-- ソートボタン -->
        <div class="sortbox" @click="addOpenClass">
          <button class="sort">
              <img src="../../assets/sort.png" alt="" class="sort-btn">
              <p id="text"></p>
          </button>
        </div>

        <div class="sort-buttons">
          <button class="sort-button" @click="sort('sortALL', 'All')">
            <img src="../../assets/sorta.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortDAY', 'D')">
            <img src="../../assets/sortd.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortWEEK', 'W')">
            <img src="../../assets/sortw.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortMONTH', 'M')">
            <img src="../../assets/sortm.png" alt="" class="sort-btn">
          </button>
          <button class="sort-button" @click="sort('sortYEAR', 'Y')">
            <img src="../../assets/sorty.png" alt="" class="sort-btn">
          </button>
        </div>

        <div class="linebottom">
          <img src="../../assets/linebottom.png" alt="">
        </div>

      </div>

      <div class="list" ref="list" @scroll="handleScroll">
          <div v-if="sortedWhispers.length === 0" class="no-posts">
            <div class="date">
              「{{formattedTodayDate}}」に<br>投稿記事はありません
            </div>
            <p class="word">
              ブロックワードにより投稿記事が表示されてない場合があります。<br>
              <a href="/blockword">ブロックワード設定</a>より確認してください。
            </p>
          </div>
          <div v-else>
              <div v-for="(whisper, index) in sortedWhispers" :key="whisper.id" class="item">

                  <router-link :to="'/postdetail/'+whisper.id" class="post">
                      <!-- <div class="content post--title" v-html="whisper.title"></div> -->
                      <div class="content post--title">
                          <h1 v-text="whisper.title"></h1>
                          <!-- <p>{{ index + 1 }}</p> -->
                          <p :class="`index-color-${index}`">{{ index + 1 }}</p>
                      </div>
                      <div class="content post--lead" v-if="whisper.lead" v-html="whisper.lead"></div>
                      <div class="content post--body" v-if="!whisper.lead" v-html="whisper.body"></div>
                  </router-link>

                  <div v-if="whisper.imageUrls && whisper.imageUrls.length > 0" class="content post--img">
                    <ul class="post--imgbox" :class="`images-${whisper.imageUrls.length}`">
                      <li v-for="(image, index) in whisper.imageUrls" :key="index" class="post--imgpin" @click="openimgModal(image)">
                        <img :src="image" alt="Selected Image" class="post--imgsize">
                      </li>
                    </ul>
                  </div>

                  <div v-if="isIMGModalOpen" class="modal" @click.self="closeimgModal">
                    <div class="modal-content">
                      <span class="close" @click="closeimgModal">&times;</span>
                      <img :src="selectedImage" alt="Modal Image" class="modal-image">
                    </div>
                  </div>


                  <div class="content post--body">
                      <div class="content post--days tagsbtn">
                          <span>タグ</span>
                          <router-link v-for="(tag, tagIndex) in whisper.tags" :key="tagIndex" :to="'/search/' + tag" @click="search(tag)">{{ tag }}</router-link>
                      </div>
                  </div>

                  <div class="post--status">
                      <router-link :to="'/postdetail/'+whisper.id" class="post--status--days" v-if="whisper.todays !== whisper.setdays">
                        <img src="../../assets/edit.png" alt="" class="smallicon todays">
                        <div class="content post--days" v-html="formatDate(whisper.todays)"></div>
                      </router-link>
                      <router-link :to="'/postdetail/'+whisper.id" class="post--status--days">
                        <img src="../../assets/timeing.png" alt="" class="smallicon">
                        <div class="content post--days" v-html="formatDate(whisper.setdays)"></div>
                      </router-link>
                      <!-- ポイント追加コンポーネント -->
                      <button class="point-button" @click="toggleModal(whisper)">
                          <img src="../../assets/LX.png" alt="" class="smallicon">
                          <span v-html="whisper.point"></span>
                      </button>
                      <!-- ポイント追加モーダル -->
                      <div class="pointModal" :class="{ open: whisper.isModalOpen }">
                          <div class="pointModalBody">
                              <div @click="() => Modalclose(whisper)" class="backbtn">
                                  <img src="../../assets/backbtn.png" alt="" class="smallicon">
                              </div>
                              <div class="pointadd">
                                  <div class="pointadd--postlx">
                                      <h3>この投稿のルクス</h3>
                                      <p>
                                          <img src="../../assets/LX.png" alt="" class="smallicon">
                                          <span v-html="whisper.point"></span>
                                      </p>
                                  </div>
                                  <div class="pointadd--addlx">
                                      <input v-model="pointToAdd" type="number" min="0" placeholder="ポイントを追加しますか？">
                                      <button :class="'point-button' + whisper.id" @click="addpoint(whisper)">
                                          <img src="../../assets/huyobtn.png" alt="" class="smallicon enter">
                                      </button>
                                  </div>
                                  <div class="pointadd--mylx">
                                      <h3>あなたのルクス</h3>
                                      <p>
                                          <span>{{ currentPoint }}</span>
                                          <button>
                                              <img src="../../assets/chargebtn.png" alt="" class="smallicon">
                                          </button>
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <!-- コメント追加コンポーネント -->
                      <commentComp :id="whisper.id" />
                      <!-- お気に入り -->

                      <button @click="togglemylist(whisper.id, whisper.originid)">
                          <img v-if="isFavorite(whisper.id)" src="../../assets/favoriteon.png" alt="お気に入りon" class="smallicon">
                          <img v-else src="../../assets/favoriteoff.png" alt="お気に入りoff" class="smallicon">
                      </button>

                      <button @click="sharemodalopen(whisper.id)">
                          <img src="../../assets/share.png" alt="共有" class="smallicon">
                      </button>

                      <div class="setting">
                          <button class="setting-button" @click="SettingButton(whisper)">
                              ...
                          </button>

                          <div class="selectbutton" v-show="whisper.isShowSelectButton">
                              <button class="rembicon" @click="deletepost(whisper.id)">削除</button>
                              <button class="editicon" @click="editpost(whisper)">編集</button>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>


    </div>
  </div>

  <EditModal :isPostModalOpen="isPostModalOpen" :whisper="selectedWhispe" @update:isPostModalOpen="updatePostModalState" />

  <div class="sharemodal">
      <!-- アカウント連携用のモーダル -->
      <div class="modal-content">
        <h2>リンクの共有</h2>
        <div class="buttonbox">
            <button @click="copyLink">
                <img src="../../assets/link1.png" alt="">
            </button>
            <button @click="shareToInstagram">
                <img src="../../assets/link2.png" alt="">
            </button>
            <button @click="shareToTwitter">
              <img src="../../assets/link3.png" alt="">
            </button>
            <button @click="shareToDiscord">
                <img src="../../assets/link4.png" alt="">
            </button>
            <button @click="shareToLine">
                <img src="../../assets/link5.png" alt="">
            </button>
            <button @click="shareToFacebook">フェイスブック共有</button>
        </div>
        <div class="close-button-box">
          <button @click="shareCancel">閉じる</button>
        </div>
      </div>
  </div>

</template>



<script>
  import TripButton from '@/components/itemComponents/tripDateButton.vue';
  // import TripButton from '@/components/itemComponents/sortDateButton.vue';
  import commentComp from '@/components/itemComponents/comment.vue';
  // import pointComp from '@/components/itemComponents/pointadd.vue';
  import EditModal from "../itemComponents/editpost.vue";
  import { ref, computed, onMounted, onBeforeMount, watchEffect, watch   } from 'vue';
  import { db } from '../../main';
  import { increment, query, getDocs, collection, where, orderBy, doc, updateDoc, getDoc, limit, startAfter, setDoc, deleteDoc, serverTimestamp  } from 'firebase/firestore';
  import { format, addDays, subDays } from 'date-fns';
  import { onAuthStateChanged } from 'firebase/auth';
  import { useStore } from 'vuex';
  import { auth } from '../../main';
  import { useRouter } from 'vue-router';

  export default {
    components: {
      commentComp,
      TripButton,
      EditModal
    },

    setup() {
      const todayDate = ref(format(new Date(), 'yyyy-MM-dd'));

      const texttodayDate = ref(new Date(todayDate.value));
      const formattedTodayDate = computed(() => {
        const date = texttodayDate.value;
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // 月を2桁に
        const dd = String(date.getDate()).padStart(2, '0'); // 日を2桁に
        return `${yyyy}/${mm}/${dd}`;
      });
      const store = useStore();
      const lastVisible = ref(null);
      const isUpdateButtonDisabled = ref(false);
      const currentUser = ref(null);
      const lastUpdateTimestamp = ref(null);
      const ispointModalOpen = ref(false);
      const router = useRouter();
      const isPostModalOpen = ref(false);
      const selectedWhispe = ref(null);

      const Wordpriset = ref([]);

      const isIMGModalOpen = ref(false);
      const selectedImage = ref(null);

      const currentPoint = ref(0); // 現在の自分のポイント数を保持するプロパティ
      const pointToAdd = ref(0);  // 追加するポイント数を保持するプロパティ

      const formatDate = (dateString) => {
        return dateString.replace(/-/g, '/');
      };


      const sortedWhispers = computed(() => store.state.sortedWhispers);
      // ブロックワード
      const blockedWords = ref([]);

      const addOpenClass = () => {
        const sortButtons = document.querySelector('.sort-buttons');
        sortButtons.classList.toggle('open');
      };

      // todayDateの変更を監視し、変更があった場合にログを表示する
      watch(todayDate, (newDate, oldDate) => {
        //console.log(`todayDate changed from ${oldDate} to ${newDate}`);

        // セッションストレージに選択した日付を追加または更新
        sessionStorage.setItem('selectDAY', newDate);
        texttodayDate.value = new Date(newDate.replace(/-/g, '/')); // texttodayDateを更新

        // ローカルストレージから sort の値を取得
        const sortValue = localStorage.getItem('sort');

        // sort の値が ALL の場合にのみ fetchWhispers を発火
        if (sortValue === 'sortALL') {
          fetchWhispers();
        }
      });


      const search = (tag) => {
          const searchParams = [];

          searchParams.push(tag);

          // ローカルストレージにsearchParamsを保存する
          localStorage.setItem('searchParams', JSON.stringify(searchParams));
          // ローカルストレージの既存のsearchTextParamsを削除する
          localStorage.removeItem('searchTextParams');
          // 新しい内容を保存する
          localStorage.setItem('searchTextParams', JSON.stringify(searchParams));
      };

      // ブロックワードを取得する関数
      const fetchBlockedWords = async () => {

          //console.log("fetchBlockedWords 関数が実行されました");

          // ユーザーがログインしている場合
          const userId = auth.currentUser.uid;
          const userBlocksCollection = collection(db, 'users', userId, 'blocks');

          try {
            const querySnapshot = await getDocs(userBlocksCollection);

            blockedWords.value = [];

            querySnapshot.forEach((doc) => {
              const blockedWord = doc.data().text; // データ構造に基づいて適切に調整してください
              blockedWords.value.push(blockedWord);
            });

            //console.log('ブロックされたワード:', blockedWords.value);
          } catch (error) {
            //console.log('ブロックされたワードの取得中にエラーが発生しました:', error);
          }
      };

      const fetchWhispers = async () => {
        await fetchBlockedWords();

        const whispersCollection = collection(db, 'whispers');
        const querySnapshot = await getDocs(
          query(whispersCollection, where('setdays', '==', todayDate.value), orderBy('point', 'desc'), limit(100))
        );

        const fetchedWhispers = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          isShowSelectButton: false, // 各whisperの初期状態を設定
          ...doc.data()
        }));

        // ブロックワードを含むWhispersをフィルタリング
        const filteredWhispers = fetchedWhispers.filter((whisper) => {
          const isBlocked = blockedWords.value.some(word => {
            return (
              whisper.title.includes(word) ||
              whisper.lead.includes(word) ||
              whisper.body.includes(word)
            );
          });
          return !isBlocked;
        });

        // ソート済みWhispersをストアにコミット
        // store.commit('setSortedWhispers', filteredWhispers);

        // sortの判定
        const sortValue = localStorage.getItem('sort');

        if (sortValue) {
          if (sortValue === 'sortALL') {
              store.commit('setSortedWhispers', filteredWhispers);
          } else if (sortValue === 'sortDAY') {
              const today = new Date();
              let pointLogs = [];

              const getPointLogsForDateTime = async (dateTime) => {
                  const pointLogRef = collection(db, 'pointLogDay', dateTime, 'whisperPoints');
                  const pointLogSnapshot = await getDocs(pointLogRef);
                  return pointLogSnapshot.docs.map(doc => ({
                      id: doc.id,
                      ...doc.data()
                  }));
              };

              const mergePointLogs = (newLogs) => {
                  newLogs.forEach(newLog => {
                      const existingLog = pointLogs.find(log => log.id === newLog.id);
                      if (existingLog) {
                          existingLog.pointsAdded += newLog.pointsAdded;
                      } else {
                          pointLogs.push(newLog);
                      }
                  });
              };

              // 現在の時間から過去24時間分のデータを取得
              for (let i = 0; i < 24; i++) {
                  const dateTime = new Date(today);
                  dateTime.setHours(today.getHours() - i);
                  const formattedDateTime = `${dateTime.getFullYear()}${String(dateTime.getMonth() + 1).padStart(2, '0')}${String(dateTime.getDate()).padStart(2, '0')}${String(dateTime.getHours()).padStart(2, '0')}`;
                  const pointLogsForDateTime = await getPointLogsForDateTime(formattedDateTime);
                  mergePointLogs(pointLogsForDateTime);
              }

              //console.log('pointLogDayから取得したドキュメント', pointLogs);

              // filteredWhispersとpointLogsのIDを比較して一致するものをrankingArrayに追加し、pointsAddedの高い順にソート
              const rankingArray = filteredWhispers
                  .filter(whisper => pointLogs.some(pointLog => pointLog.id === whisper.id))
                  .map(whisper => {
                      const matchedPointLog = pointLogs.find(pointLog => pointLog.id === whisper.id);
                      return {
                          ...whisper,
                          pointsAdded: matchedPointLog ? matchedPointLog.pointsAdded : 0
                      };
                  })
                  .sort((a, b) => b.pointsAdded - a.pointsAdded); // pointsAddedの高い順にソート

              // ポイントが追加されていないWhispersを追加
              const noPointsAddedWhispers = filteredWhispers.filter(whisper => !pointLogs.some(pointLog => pointLog.id === whisper.id));

              const finalRankingArray = [...rankingArray, ...noPointsAddedWhispers];

              store.commit('setSortedWhispers', finalRankingArray);

              alert('24時間以内のポイント追加順でソートしました');
          } else if (sortValue === 'sortWEEK') {
              const today = new Date();
              let pointLogs = [];

              const getPointLogsForDate = async (date) => {
                  const pointLogRef = collection(db, 'pointLog', date, 'whisperPoints');
                  const pointLogSnapshot = await getDocs(pointLogRef);
                  return pointLogSnapshot.docs.map(doc => ({
                      id: doc.id,
                      ...doc.data()
                  }));
              };

              const mergePointLogs = (newLogs) => {
                  newLogs.forEach(newLog => {
                      const existingLog = pointLogs.find(log => log.id === newLog.id);
                      if (existingLog) {
                          existingLog.pointsAdded += newLog.pointsAdded;
                      } else {
                          pointLogs.push(newLog);
                      }
                  });
              };

              // 一週間分の日付を取得
              for (let i = 0; i < 7; i++) {
                  const date = new Date(today);
                  date.setDate(today.getDate() - i);
                  const formattedDateForDoc = date.toISOString().split('T')[0].replace(/-/g, '');
                  const pointLogsForDate = await getPointLogsForDate(formattedDateForDoc);
                  mergePointLogs(pointLogsForDate);
              }

              //console.log('一週間のpointLogから取得したドキュメント', pointLogs);

              // filteredWhispersとpointLogsのIDを比較して一致するものをrankingArrayに追加し、pointsAddedの高い順にソート
              const rankingArray = filteredWhispers
                  .filter(whisper => pointLogs.some(pointLog => pointLog.id === whisper.id))
                  .map(whisper => {
                      const matchedPointLog = pointLogs.find(pointLog => pointLog.id === whisper.id);
                      return {
                          ...whisper,
                          pointsAdded: matchedPointLog ? matchedPointLog.pointsAdded : 0
                      };
                  })
                  .sort((a, b) => b.pointsAdded - a.pointsAdded); // pointsAddedの高い順にソート

              // ポイントが追加されていないWhispersを追加
              const noPointsAddedWhispers = filteredWhispers.filter(whisper => !pointLogs.some(pointLog => pointLog.id === whisper.id));

              const finalRankingArray = [...rankingArray, ...noPointsAddedWhispers];

              store.commit('setSortedWhispers', finalRankingArray);

              alert('一週間以内のポイント追加順でソートしました');
          } else if (sortValue === 'sortMONTH') {
            const today = new Date();
            let pointLogs = [];

            const getPointLogsForDate = async (date) => {
                const pointLogRef = collection(db, 'pointLog', date, 'whisperPoints');
                const pointLogSnapshot = await getDocs(pointLogRef);
                return pointLogSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            };

            const mergePointLogs = (newLogs) => {
                newLogs.forEach(newLog => {
                    const existingLog = pointLogs.find(log => log.id === newLog.id);
                    if (existingLog) {
                        existingLog.pointsAdded += newLog.pointsAdded;
                    } else {
                        pointLogs.push(newLog);
                    }
                });
            };

            // 一ヶ月分の日付を取得
            const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

            for (let i = 0; i < daysInMonth; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const formattedDateForDoc = date.toISOString().split('T')[0].replace(/-/g, '');
                const pointLogsForDate = await getPointLogsForDate(formattedDateForDoc);
                mergePointLogs(pointLogsForDate);
            }

            //console.log('一ヶ月のpointLogから取得したドキュメント', pointLogs);

            // filteredWhispersとpointLogsのIDを比較して一致するものをrankingArrayに追加し、pointsAddedの高い順にソート
            const rankingArray = filteredWhispers
                .filter(whisper => pointLogs.some(pointLog => pointLog.id === whisper.id))
                .map(whisper => {
                    const matchedPointLog = pointLogs.find(pointLog => pointLog.id === whisper.id);
                    return {
                        ...whisper,
                        pointsAdded: matchedPointLog ? matchedPointLog.pointsAdded : 0
                    };
                })
                .sort((a, b) => b.pointsAdded - a.pointsAdded); // pointsAddedの高い順にソート

            // ポイントが追加されていないWhispersを追加
            const noPointsAddedWhispers = filteredWhispers.filter(whisper => !pointLogs.some(pointLog => pointLog.id === whisper.id));

            const finalRankingArray = [...rankingArray, ...noPointsAddedWhispers];

            store.commit('setSortedWhispers', finalRankingArray);

            alert('一カ月以内のポイント追加順でソートしました');
          } else if (sortValue === 'sortYEAR') {
            const today = new Date();
            let pointLogs = [];

            const getPointLogsForDate = async (date) => {
                const pointLogRef = collection(db, 'pointLog', date, 'whisperPoints');
                const pointLogSnapshot = await getDocs(pointLogRef);
                return pointLogSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            };

            const mergePointLogs = (newLogs) => {
                newLogs.forEach(newLog => {
                    const existingLog = pointLogs.find(log => log.id === newLog.id);
                    if (existingLog) {
                        existingLog.pointsAdded += newLog.pointsAdded;
                    } else {
                        pointLogs.push(newLog);
                    }
                });
            };

            // 一年間分の日付を取得
            for (let i = 0; i < 365; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() - i);
                const formattedDateForDoc = date.toISOString().split('T')[0].replace(/-/g, '');
                const pointLogsForDate = await getPointLogsForDate(formattedDateForDoc);
                mergePointLogs(pointLogsForDate);
                //console.log('一年間のformattedDateForDoc', formattedDateForDoc);
            }

            //console.log('一年間のpointLogから取得したドキュメント', pointLogs);

            // filteredWhispersとpointLogsのIDを比較して一致するものをrankingArrayに追加し、pointsAddedの高い順にソート
            const rankingArray = filteredWhispers
                .filter(whisper => pointLogs.some(pointLog => pointLog.id === whisper.id))
                .map(whisper => {
                    const matchedPointLog = pointLogs.find(pointLog => pointLog.id === whisper.id);
                    return {
                        ...whisper,
                        pointsAdded: matchedPointLog ? matchedPointLog.pointsAdded : 0
                    };
                })
                .sort((a, b) => b.pointsAdded - a.pointsAdded); // pointsAddedの高い順にソート

            // ポイントが追加されていないWhispersを追加
            const noPointsAddedWhispers = filteredWhispers.filter(whisper => !pointLogs.some(pointLog => pointLog.id === whisper.id));

            const finalRankingArray = [...rankingArray, ...noPointsAddedWhispers];

            store.commit('setSortedWhispers', finalRankingArray);

            alert('一年以内のポイント追加順でソートしました');
          } else {
            //console.log('sortに値はない')
            // ソート済みWhispersをストアにコミット
            store.commit('setSortedWhispers', filteredWhispers);
          }

        } else {
          //console.log('sortに値はない')
          // ソート済みWhispersをストアにコミット
          store.commit('setSortedWhispers', filteredWhispers);
        }

        // 最後に取得したドキュメントをlastVisibleに設定
        lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
      };

      // 下記のコードのAddpostlinkにその投稿のドキュメント名をfirestoreのコレクション名usersのドキュメント名uidのサブコレクション名addpointPost内に追加する機能を書いて

      const addpoint = async (whisper) => {
          // 今日の日付と時間を現地時間で "yyyyMMddHH" 形式で取得
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const day = String(today.getDate()).padStart(2, '0');
          const hours = String(today.getHours()).padStart(2, '0');
          const formattedDateForDoc = `${year}${month}${day}`;
          const formattedDateForDocTIME = `${year}${month}${day}${hours}`;
          //console.log('formattedDateForDoc', formattedDateForDoc);
          //console.log('formattedDateForDocTIME', formattedDateForDocTIME);

          //console.log('ポイント関連', whisper);
          const whisperId = whisper.id;
          const docRefpost = doc(db, 'whispers', whisperId);
          const docRefuser = doc(db, 'users', auth.currentUser.uid);

          // 'pointLog' コレクションの今日の日付をドキュメント名とした新しいドキュメント参照を作成
          const docReflog = doc(db, 'pointLog', formattedDateForDoc);
          const docReflogDay = doc(db, 'pointLogDay', formattedDateForDocTIME);

          try {
              // 現在の投稿とユーザーのデータを取得
              const postDoc = await getDoc(docRefpost);
              const userDoc = await getDoc(docRefuser);

              const newpostPoints = postDoc.data().point + parseInt(pointToAdd.value);
              const newmyPoints = userDoc.data().point - parseInt(pointToAdd.value);

              // ポイントが0の場合、アラートを表示して処理を中断
              if (parseInt(pointToAdd.value) <= 0 || isNaN(parseInt(pointToAdd.value))) {
                  window.alert('ポイントを入力してください');
                  return;
              }

              // 現在の所持ポイントが足りない場合、アラートを表示して処理を中断
              if (currentPoint.value < parseInt(pointToAdd.value)) {
                  window.alert('所持ポイントが足りません');
                  return;
              }

              await updateDoc(docRefpost, { point: newpostPoints });
              await updateDoc(docRefuser, { point: newmyPoints });

              // サブコレクション 'whisperPoints' に追加または更新 (pointLog)
              const subCollectionRef = collection(docReflog, 'whisperPoints');
              const newDocRef = doc(subCollectionRef, whisperId);
              const newDocSnap = await getDoc(newDocRef);

              if (newDocSnap.exists()) {
                  const existingPoints = newDocSnap.data().pointsAdded;
                  await updateDoc(newDocRef, {
                      pointsAdded: existingPoints + parseInt(pointToAdd.value),
                      originid: whisper.originid
                  });
              } else {
                  await setDoc(newDocRef, {
                      pointsAdded: parseInt(pointToAdd.value),
                      originid: whisper.originid
                  });
              }

              // サブコレクション 'whisperPoints' に追加または更新 (pointLogDay)
              const subCollectionRefDAY = collection(docReflogDay, 'whisperPoints');
              const newDocRefDay = doc(subCollectionRefDAY, whisperId);
              const newDocSnapDAY = await getDoc(newDocRefDay);

              if (newDocSnapDAY.exists()) {
                  const existingPointsDAY = newDocSnapDAY.data().pointsAdded;
                  await updateDoc(newDocRefDay, {
                    pointsAdded: existingPointsDAY + parseInt(pointToAdd.value),
                    originid: whisper.originid
                  });
              } else {
                  await setDoc(newDocRefDay, {
                      pointsAdded: parseInt(pointToAdd.value),
                      originid: whisper.originid
                  });
              }

              // 投稿リンクを追加
              await Addpostlink(whisperId);

              //console.log('ポイントが更新されました。');
              pointToAdd.value = 0;
              whisper.isModalOpen = false;

              // アラートを表示
              window.alert('ポイントを追加しました');

              // サイトをリロード
              window.location.reload();
          } catch (e) {
              //console.log('ポイントの更新に失敗しました。', e);
              window.alert('ポイントの更新に失敗しました。');
          }
      };


      const Addpostlink = async (whisperId) => {
        try {
          const userId = auth.currentUser.uid;
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
          const userPostsCollectionRef = collection(db, 'users', userId, 'addpointPost');

          // 投稿のドキュメント ID を追加
          await setDoc(doc(userPostsCollectionRef, whisperId), {});

          //console.log('投稿リンクが追加されました');
        } catch (error) {
          //console.log('投稿リンクの追加中にエラーが発生しました:', error);
        }
      };

      const SettingButton = async (whisper) => {
        const userId = auth.currentUser.uid;
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        const userOriginid = userDoc.data().originid;

        if (whisper.originid === userOriginid) {
          //console.log("同じ");
          whisper.isShowSelectButton = !whisper.isShowSelectButton; // 各whisperの状態を切り替える
          //console.log('isShowSelectButtonの値:', whisper.isShowSelectButton); // プロパティの値をコンソールに出力する
        } else {
          // 遷移処理
          router.push('/postdetail/' + whisper.id);
        }
      };

      const userMypoint = async () => {
        try {
          const userId = auth.currentUser.uid;  // user パラメータを使用する

          // Firestoreからユーザードキュメントの取得
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);

          // ポイントを取得
          const userPoint = userDoc.data().point || 0;

          // currentPoint にセット
          currentPoint.value = userPoint;

          //console.log('currentPoint.value', currentPoint.value);

          //console.log('ユーザーのmylistを取得し、ストアに追加しました');
        } catch (error) {
          //console.log('ユーザーのmylistを取得中にエラーが発生しました:', error);
        }
      }

      const userMylist = async () => {
        try {
          if (!auth.currentUser) {
            //console.log('ユーザーがログインしていません');
            return;
          }
          const userId = auth.currentUser.uid;
          // Firestoreからユーザードキュメントのmylistサブコレクションを取得
          const mylistCollectionRef = collection(db, 'users', userId, 'myfavoritePost');
          const mylistQuerySnapshot = await getDocs(mylistCollectionRef);

          // ユーザーのmylist配列を初期化
          const mylist = [];

          // mylistサブコレクション内のドキュメントを配列に追加
          mylistQuerySnapshot.forEach((doc) => {
            mylist.push(doc.id);
          });

          // 取得した mylist を Vuex ストアに追加
          store.commit('setUserMylist', mylist);

          //console.log('ユーザーのmylistを取得し、ストアに追加しました');
        } catch (error) {
          //console.log('ユーザーのmylistを取得中にエラーが発生しました:', error);
        }
      };

      const getNextDate = () => {
          try {
              const nextDate = addDays(new Date(todayDate.value), 1);
              todayDate.value = format(nextDate, 'yyyy-MM-dd');
              // セッションストレージに選択した日付を追加または更新
              sessionStorage.setItem('selectDAY', todayDate.value);
              fetchWhispers();
          } catch (error) {
              if (error instanceof RangeError && error.message.includes('Invalid time value')) {
                  console.error('Invalid time value error occurred:', error);
                  location.reload(); // サイトをリロード
              } else {
                  throw error; // 他のエラーは再スロー
              }
          }
      };

      const getBackDate = () => {
          try {
              const prevDate = subDays(new Date(todayDate.value), 1);
              todayDate.value = format(prevDate, 'yyyy-MM-dd');
              // セッションストレージに選択した日付を追加または更新
              sessionStorage.setItem('selectDAY', todayDate.value);
              fetchWhispers();
          } catch (error) {
              if (error instanceof RangeError && error.message.includes('Invalid time value')) {
                  console.error('Invalid time value error occurred:', error);
                  location.reload(); // サイトをリロード
              } else {
                  throw error; // 他のエラーは再スロー
              }
          }
      };


      const updateTodayDate = (newDate) => {
          if (isUpdateButtonDisabled.value) {
              const elapsedSeconds = Math.floor((Date.now() - lastUpdateTimestamp.value) / 1000);
              const remainingSeconds = 3 - elapsedSeconds; // 30を3に変更

              if (remainingSeconds > 0) {
                  alert(`あと${remainingSeconds}秒でトリップボタンが使用可能です。`);
                  return;
              }
          }

          isUpdateButtonDisabled.value = true;
          //console.log("変更前の日付", todayDate.value);
          let formattedNewDate = format(new Date(newDate), 'yyyy-MM-dd');

          // 新しい日付が現在の日付と同じ場合、もう一度新しい日付を取得
          while (formattedNewDate === todayDate.value) {
              // 新しい日付を再取得
              formattedNewDate = format(new Date(), 'yyyy-MM-dd');
          }

          //console.log("変更後の日付", todayDate.value);

          todayDate.value = formattedNewDate;
          fetchWhispers();

          lastUpdateTimestamp.value = Date.now();

          setTimeout(() => {
              isUpdateButtonDisabled.value = false;
          }, 3000); // 30000を3000に変更
      };


      const togglemylist = async (whisperId,whisperOriginid) => {
        //console.log('whisperId:', whisperId);
        //console.log('whisperId:', whisperOriginid);
        // ユーザーがログインしているか確認
        if (!auth.currentUser) {
          // ユーザーがログインしていない場合の処理
          //console.log('ユーザーがログインしていません');
          return;
        }

        try {
          const userId = auth.currentUser.uid;

          // Firestoreからユーザードキュメントを取得
          const userDocRef = doc(db, 'users', userId, 'myfavoritePost', whisperId);
          const userDoc = await getDoc(userDocRef);

          // 今日の日付を取得してフォーマット
          const today = new Date();
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const day = String(today.getDate()).padStart(2, '0');
          const formattedDate = `${year}${month}${day}`;

          // favoriteLogコレクション内の今日の日付のドキュメント参照を作成
          const commentLogDocRef = doc(db, 'favoriteLog', formattedDate);
          const wisperFavoriteCollectionRef = collection(commentLogDocRef, 'Wisperfavorite');
          const newCommentDocRef = doc(wisperFavoriteCollectionRef, whisperId);

          if (userDoc.exists()) {
            // ドキュメントが存在する場合、削除
            await deleteDoc(userDocRef);
            //console.log('whisperIdがmylist内に存在するため削除しました');

            // Wisperfavorite内の該当するドキュメントのpointsAddedから1ポイント引く
            const newCommentDocSnap = await getDoc(newCommentDocRef);
            if (newCommentDocSnap.exists() && newCommentDocSnap.data().pointsAdded > 0) {
              await updateDoc(newCommentDocRef, {
                pointsAdded: newCommentDocSnap.data().pointsAdded - 1
              });
              //console.log('Wisperfavoriteのポイントが1ポイント引かれました');
            }
          } else {
            // ドキュメントが存在しない場合、作成
            await setDoc(userDocRef, {});
            //console.log('whisperIdがmylist内に存在しないため作成しました');

            // Wisperfavorite内にデータを追加または更新
            const newCommentDocSnap = await getDoc(newCommentDocRef);
            if (newCommentDocSnap.exists()) {
              // ドキュメントが存在する場合、pointsAddedに1を加算
              await updateDoc(newCommentDocRef, {
                pointsAdded: newCommentDocSnap.data().pointsAdded + 1
              });
              //console.log('Wisperfavoriteのポイントが更新されました');
            } else {
              // ドキュメントが存在しない場合、新規作成
              await setDoc(newCommentDocRef, {
                pointsAdded: 1,
                originid: whisperOriginid
              });
              //console.log('Wisperfavoriteにポイントとoriginidが追加されました');
            }
          }

          // ユーザーのmylistを再取得
          userMylist();

          //console.log('ユーザーのmylistが正常に更新されました');
        } catch (error) {
          //console.log('ユーザーのmylistを更新中にエラーが発生しました:', error);
        }
      };

      const isFavorite = (whisperId) => {
        return store.state.userMylist.includes(whisperId);
      };

      let isScrollHandling = false;

      const handleScroll = async () => {
        //console.log("handleScroll 関数が呼ばれました");
        const list = document.querySelector('.list');
        const isAtBottom = list.scrollHeight - list.scrollTop === list.clientHeight;

        if (isAtBottom && !isScrollHandling) {
          isScrollHandling = true;

          try {
            await loadNextPage();
          } catch (error) {
            //console.log('ページの読み込み中にエラーが発生しました:', error);
          } finally {
            // 一定時間後に再び呼び出し可能にする
            setTimeout(() => {
              isScrollHandling = false;
            }, 600000); // 600000ミリ秒（十分）後
          }
        }
      };

      const loadNextPage = async () => {
        try {
          const whispersCollection = collection(db, 'whispers');
          const querySnapshot = await getDocs(
            query(
              whispersCollection,
              where('setdays', '==', todayDate.value),
              orderBy('point', 'desc'),
              startAfter(lastVisible.value),
              limit(100)
            )
          );

          const fetchedWhispers = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

          // もしもっとドキュメントがなければ、読み込みを停止
          if (querySnapshot.docs.length === 0) {
            //console.log('読み込むWhisperがもうありません。');
            return;
          }

          // 新しいWhisperをフィルタリングしてsortedWhispersに追加
          const newWhispers = fetchedWhispers.filter((whisper) => {
            const isBlocked = blockedWords.value.some(word => whisper.body.includes(word));
            return !isBlocked;
          });

          // Vuex ストアに新しいWhispersを追加
          store.commit('addSortedWhispers', newWhispers);

          // 次のクエリのためにlastVisibleを更新
          lastVisible.value = querySnapshot.docs[querySnapshot.docs.length - 1];
        } catch (error) {
          //console.log('次のページを読み込む際にエラーが発生しました:', error);
        }
        //console.log("loadNextPage 関数が完了しました");
      };

      onAuthStateChanged(auth, (user) => {
        // ユーザーのログイン状態が変更された時のコールバック
        //console.log('動く');
        const authLog = localStorage.getItem('authlog');

        // authlogがtrueであるかをチェック
        if (authLog === 'true') {
          fetchWhispers();
        }
      });

      const toggleModal = (whisper) => {
        if (!whisper.isModalOpen) {
          userMypoint();
        }
        whisper.isModalOpen = !whisper.isModalOpen;
      };

      const Modalclose = (whisper) => {
        //console.log('動く');
        whisper.isModalOpen = false;
      };

      const deletepost = async (whisperId) => {
        // アラートを表示し、ユーザーが確認するまで処理を中断する
        const confirmDelete = window.confirm("削除しますか？");
        if (!confirmDelete) {
          return;
        }
        try {
          // 削除前に投稿のデータを取得
          const docRef = doc(db, 'whispers', whisperId);
          const whisperDoc = await getDoc(docRef);
          const originid = whisperDoc.data().originid;

          // 現在のユーザーの originid を取得
          const userId = auth.currentUser.uid;
          const userDocRef = doc(db, 'users', userId);
          const userDoc = await getDoc(userDocRef);
          const userOriginid = userDoc.data().originid;

          // originid が異なる場合は削除しない
          if (originid !== userOriginid) {
            return;
          }

          // 削除処理を実行
          await deleteDoc(docRef);

          //console.log("投稿を削除しました");

          // 投稿を削除した後にサイトをリロード
          window.location.reload();
        } catch (error) {
          //console.log("投稿の削除中にエラーが発生しました:", error);
        }
      };

      const editpost = async (whisper) => {
        if (currentUser.value) {
          //console.log('編集だぜ',whisper);
          isPostModalOpen.value = true;
          selectedWhispe.value = whisper
        } else {
          //console.log("ユーザーがログインしていません。モーダルを開くことができません。");
        }
      }

      const updatePostModalState = (newState) => {
          isPostModalOpen.value = newState;
      };

      const prisetSearch = () => {
        const wordPrisetFromStorage = localStorage.getItem('WordPriset');
        if (wordPrisetFromStorage) {
          const wordPrisetArray = JSON.parse(wordPrisetFromStorage);

          if (wordPrisetArray.length === 0) {
            router.push('/searchpriset/');
          } else {
            localStorage.removeItem('searchTextParams');
            localStorage.setItem('searchTextParams', JSON.stringify(wordPrisetArray));
            router.push('/search/' + wordPrisetArray);
          }
        }
      };

      const openimgModal = (image) => {
        //console.log("開ける");
        selectedImage.value = image;
        isIMGModalOpen.value = true;
      };

      const closeimgModal = () => {
        //console.log("閉じる");
        isIMGModalOpen.value = false;
        selectedImage.value = null;
      };

      const sharemodalopen = (whisperId) => {
        const sharemodal = document.querySelector('.sharemodal');
        if (sharemodal) {
            sharemodal.classList.add('open');
            selectedWhispe.value = whisperId; // 共有する whisperId を保存
        }
      };

      const shareToTwitter = () => {
          const whisperId = selectedWhispe.value;
          const url = `https://diposty.com/postdetail/${whisperId}`;
          const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent('#DiPosty')}`;
          window.open(twitterUrl, '_blank');
      };

      const shareToFacebook = () => {
          const whisperId = selectedWhispe.value;
          const url = `https://diposty.com/postdetail/${whisperId}`;
          const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
          window.open(facebookUrl, '_blank');
      };

      const copyLink = async () => {
          const whisperId = selectedWhispe.value;
          const url = `https://diposty.com/postdetail/${whisperId}`;
          try {
              await navigator.clipboard.writeText(url);
              alert('リンクをコピーしました');
          } catch (error) {
              console.error('リンクのコピーに失敗しました:', error);
          }
      };

      const shareToInstagram = async () => {
          const whisperId = selectedWhispe.value;
          const url = `https://diposty.com/postdetail/${whisperId}`;
          try {
              await navigator.clipboard.writeText(url);
              alert('リンクをコピーしました。インスタグラムの投稿に貼り付けてください。');
              window.open('https://www.instagram.com', '_blank');
          } catch (error) {
              console.error('リンクのコピーに失敗しました:', error);
          }
      };

      const shareToLine = () => {
          const whisperId = selectedWhispe.value;
          const url = `https://diposty.com/postdetail/${whisperId}`;
          const lineUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}`;
          window.open(lineUrl, '_blank');
      };

      const shareToDiscord = async () => {
          const whisperId = selectedWhispe.value;
          const url = `https://diposty.com/postdetail/${whisperId}`;
          try {
              await navigator.clipboard.writeText(url);
              alert('リンクをコピーしました。Discordアプリを開いて、リンクを貼り付けてください。');
              window.open('https://discord.com/channels/@me', '_blank');
          } catch (error) {
              console.error('リンクのコピーに失敗しました:', error);
          }
      };


      const shareCancel = () => {
          const sharemodal = document.querySelector('.sharemodal');
          if (sharemodal) {
              sharemodal.classList.remove('open');
          }
      };

      onMounted(async () => {
        auth.onAuthStateChanged(async user => {
          currentUser.value = user;
          userMylist();
          if (user) {
            const uid = user.uid;

            // 支払い情報が存在するか確認
            const paymentsCollectionRef = collection(db, 'customers', uid, 'payments');
            const paymentsQuerySnapshot = await getDocs(paymentsCollectionRef);
            if (!paymentsQuerySnapshot.empty) {
              // ポイントの更新処理を呼び出す
              await updatePoint(uid);
            } else {
              //console.log("ポイント更新はない！！！！！！！！！！")
            }

            const userDocRef = doc(db, 'users', uid);
            const wordPrisetCollectionRef = collection(userDocRef, 'WordPriset');
            try {
              const querySnapshot = await getDocs(wordPrisetCollectionRef);
              querySnapshot.forEach(doc => {
                // ドキュメントの内容を取得し、localhostにセットするなどの処理を行う
                Wordpriset.value.push(doc.data().text);
              });
              // 取得したデータでローカルストレージを更新
              localStorage.setItem('WordPriset', JSON.stringify(Wordpriset.value));
              //console.log('Wordpriset内容', Wordpriset.value);
            } catch (error) {
              console.error('Error getting documents: ', error);
            }
          }
          // セッションストレージのselectDAYの値を確認し、todayDateに設定
          const selectDAY = sessionStorage.getItem('selectDAY');
          if (selectDAY) {
            todayDate.value = selectDAY;
          } else {
            // todayDateの値をセッションストレージに設定
            sessionStorage.setItem('selectDAY', todayDate.value);
          }
        });

        // ローカルストレージから sort の値を取得
        let sortValue = localStorage.getItem('sort');

        // sort の値が ALL 以外の場合、ALL に変更
        if (sortValue !== 'sortALL') {
          sortValue = 'sortALL';
          localStorage.setItem('sort', 'sortALL');
        }

        // ボタンテキストを設定する関数
        const setButtonText = (text) => {
          const sortButton = document.querySelector('#text');
          sortButton.textContent = text;
        };

        // sortValue の値に応じてボタンテキストを設定
        switch (sortValue) {
          case 'sortALL':
            setButtonText('All');
            break;
          case 'sortDAY':
            setButtonText('D');
            break;
          case 'sortWEEK':
            setButtonText('W');
            break;
          case 'sortMONTH':
            setButtonText('M');
            break;
          case 'sortYEAR':
            setButtonText('Y');
            break;
          default:
            // デフォルトのテキストを設定する場合はここに追加
            break;
        }

      });


      const updatePoint = async (uid) => {
        // 支払い情報を取得しポイントに加算する処理
        const paymentsCollectionRef = collection(db, 'customers', uid, 'payments');
        const paymentsQuerySnapshot = await getDocs(paymentsCollectionRef);
        let totalAmount = 0;

        // 支払い情報をループして合計額を計算
        paymentsQuerySnapshot.forEach(doc => {
          totalAmount += doc.data().amount;
        });

        // ポイントに加算（×7）
        const pointsToAdd = totalAmount * 7;
        const userDocRef = doc(db, 'users', uid);
        await updateDoc(userDocRef, {
          point: increment(pointsToAdd)
        });

        // 支払い情報を削除
        const deletePromises = [];
        paymentsQuerySnapshot.forEach(doc => {
          deletePromises.push(deleteDoc(doc.ref));
        });

        await Promise.all(deletePromises);

        // ポイント追加後のアラートとリロード
        alert(`${pointsToAdd}ポイントを追加しました！`);
        window.location.reload();
      };


      const sort = (name, buttonText) => {
        if (buttonText == 'None') {
              localStorage.setItem('sort', '');
        } else {
            localStorage.setItem('sort', name);
        }
        // fetchWhispers 関数の前に、ボタンのテキストを設定する
        const sortButton = document.querySelector('#text');
        sortButton.textContent = buttonText;
        const sortButtons = document.querySelector('.sort-buttons');
        sortButtons.classList.remove('open');

        fetchWhispers();
      };

      return {
        updatePoint,
        shareToLine,
        shareToDiscord,
        shareToInstagram,
        formattedTodayDate,
        texttodayDate,
        shareToFacebook,
        shareToTwitter,
        shareCancel,
        sharemodalopen,
        formatDate,
        isIMGModalOpen,
        selectedImage,
        openimgModal,
        closeimgModal,
        prisetSearch,
        Wordpriset,
        sort,
        sortedWhispers,
        todayDate,
        currentPoint,
        router,
        pointToAdd,
        ispointModalOpen,
        lastUpdateTimestamp,
        getNextDate,
        getBackDate,
        updateTodayDate,
        togglemylist,
        userMylist,
        isFavorite,
        handleScroll,
        loadNextPage,
        toggleModal,
        Modalclose,
        addpoint,
        SettingButton,
        deletepost,
        editpost,
        isPostModalOpen,
        updatePostModalState,
        selectedWhispe,
        Addpostlink,
        search,
        addOpenClass,
        copyLink // 追加
      };

  },
};

</script>





<style lang="scss" scoped>

.sharemodal{
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3232328c;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
    &.open{
        display: flex;
    }
    .modal-content {
        background-color: #323232;
        color: #FFF;
        padding: 22px 25px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
        gap: 16px;
        border-radius: 10px;
        border: 1px solid #00A8A8;
        width: auto;
        height: auto;
        h2{
          font-size: 16px;
          font-weight: bold;
        }
        .buttonbox{
          display: flex;
          gap: 16px;
        }
        .close-button-box{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          button{
            background-color: #D9D9D9;
            color: #323232;
            border-radius: 11px;
            padding: 3px 12px;
          }
        }
    }
}


.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: #e9e9e9;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  height: 400px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.close {
  position: absolute;
  top: -30px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.home{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.inner{
  // width: 85.47rem;
  width: 600px;
  border-left: 5px solid #10AE33;; 
  border-right: 5px solid #10AE33;; 
  @media (max-width: 599px) {
    // width: 87rem;
    width: 100%;
    border-left: unset; 
    border-right: unset; 
  }
}

.top{
  position: fixed;
  display: flex;
  height: 200px;
  width: 590px;
  z-index: 900;
  min-width: 450px;
  @media (max-width: 599px) {
    // width: 85.47rem;
    width: 100%;
    height: 160px;
  }
  background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
  &--back{
    margin-right: 1vw;
  }
  &--next{
    margin-left: 1vw;
  }
  .icon-img{
    width: 69px;
    height: 69px;
    position: absolute;
    top: 30%;
    left: 5%;
    @media (max-width: 599px) {
      position: absolute;
      width: 55px;
      height: 55px;
      top: 130px;
      left: 45px;
      transform: translate(-50%, -50%);
    }
  }
}
.top-calendar{
  position: absolute;
  top: -4%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  // width: 85.47rem;
  width: 590px;
  @media (max-width: 599px) {
    // width: 85.47rem;
    width: 100%;
    height: 120px;
  }
  // transform: translate(3%, 0%);
  color: #fff;
}
.list{
  // width: 85.47rem;
  width: 590px;
  height: 100vh;
  padding: 200px 0 60px 0;
  overflow-y: scroll;
  color: #fff;
  background-color: #323232;
  @media (max-width: 599px) {
    // width: 85.47rem;
    width: 100%;
    padding: 160px 0 40px 0;
  }
}
.list {
  .no-posts{
    list-style: none;
    padding: 5px 15px;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-direction: column;
    background: linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;
    text-align:center;
    height: 100%;
    padding-top:30px;
    .date{
      font-size:36px;
      @media (max-width: 599px) {
        font-size:32px;
      }
    }
    .word{
      letter-spacing: 2.5px;
      line-height: 1.5;
      a{
        color:#00a8a8;
      }
    }
  }
  // スクロールバーを非表示にする
  &::-webkit-scrollbar {
    display: none;
  }

  // スクロール可能な状態でスクロールバーを表示する
  &:hover,
  &:active,
  &:focus {
    &::-webkit-scrollbar {
      display: block;
      width: 0px; // スクロールバーの幅
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #828f85; // スクロールバーの色
      border-radius: 5px;
    }
  }
}
.item {
  list-style: none;
  // border-top: 1px solid #eee;
  padding: 5px 15px;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
  background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
  .post{
    list-style: none;
    // padding: 5px 15px;
    display: flex;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    position: relative;
    gap: 1rem;
    flex-direction: column;
  }
}
.item:nth-child(1) {
  // box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.79);
  box-shadow: inset 0 0 10px #00a8a8;
  // z-index: 999;
  // position: relative;
}
.item:hover{
  background:#323232;
}
.top--datepicker{
  font-weight: bold;
  font-size: 32px;
  font-family: "Noto Sans JP", sans-serif;
  @media (max-width: 599px) {
    font-size: 40px;
  }
}

#datepicker{
  margin-top: 10px;
  width: 65%;
  @media (max-width: 599px) {
    width: 280px;
  }
}
.calendar {
  text-align: center;
  width: 60%;
  @media (max-width: 599px) {
    width: 100%;
  }
  input[type="date"] {
    background-image: url("../../assets/calendar.png"); // カスタムアイコンのパス
    background-repeat: no-repeat;
    background-position: right 10px center; // アイコンの位置を右側に設定
    cursor: pointer; // カーソルをポインターに設定
    -webkit-appearance: none; // ブラウザデフォルトのスタイルを無効化
    appearance: none;
    background-size: 20px; // アイコンのサイズを指定
    // padding-right: 20px; // パディングを追加してテキストとアイコンが重ならないようにする
    text-align: left;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0; // インジケーターを透明にする
    // position: absolute; // インジケーターの位置を調整
    width: 20px; // インジケーターの幅をアイコンの幅に合わせる
    height: 20px; // インジケーターの高さをアイコンの高さに合わせる
    right: 10px; // 右からの位置を指定
    cursor: pointer; // カーソルをポインターに設定
  }
}

.linebottom{
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%); 
  @media (max-width: 599px) {
    top: 74%;
    z-index: -1;
  }
  img{
    height: 77%;
    // width: 33rem;
    @media (max-width: 599px) {
      // height: 35px;
      width: 280px;

    }
  }
}

  .tagsbtn{
    span{
      font-size: 2rem;
      margin-right: 5px;
      letter-spacing: 0px;
      background: #00a8a8;
      color: #ffffff;
      font-size: 12px;
      padding: 2px 5px 2px 2px;
      border-radius: 0 15px 15px 0;
      font-weight: 400;
      margin-right: 5px;
      letter-spacing: 0px;
      @media (max-width: 599px) {
        font-size: 2.4rem;
        padding: 2px 5px 2px 2px;
        border-radius: 0 15px 15px 0;
        line-height: 2;
      }
    }
    a{
      background: #C1C1C1;
      color: #323232;
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 15px;
      font-weight: 600;
      margin-right: 5px;
      letter-spacing: 0px;
      @media (max-width: 599px) {
        font-size: 2.4rem;
        padding: 2px 6px;
        border-radius: 15px;
        line-height: 2;
      }
    }
  }
  .tagsbtn a:empty {
    display: none;
  }
.post{
  display: flex;
  &--title{
    height: auto;
    min-height: 3rem;
    // max-height: 70rem;
    line-height: 7rem;
    font-weight: 800;
    font-size: 2.6rem;
    // margin-top: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    @media (max-width: 599px) {
      font-size: 3rem;
    }
    h1{
      z-index: 1;
      position: relative;
      line-height: 1.5;
      letter-spacing: 1px;
    }
    p{
      position: absolute;
      top: 0x;
      z-index: 0;
      font-size: 56px;
      font-weight: 400;
      color: rgba(0, 168, 168, 0.3137254902);
      white-space: pre-wrap;
      word-wrap: break-word;
      line-break: strict;
      line-height: 1;
      letter-spacing: -7px;
      @media (max-width: 599px) {
        font-size: 8rem;
      }
    }
    .index-color-0 { 
      color: #A7AA0D80; 
      font-family: "Jost", sans-serif;
      // font-family: "Stardos Stencil", system-ui;
    } /* 1番目 */
    .index-color-1 { 
      color: #93A2A250; 
      font-family: "Jost", sans-serif;
      // font-family: "Stardos Stencil", system-ui;
    } /* 2番目 */
    .index-color-2 { 
      color: #C1671350; 
      font-family: "Jost", sans-serif;
      // font-family: "Stardos Stencil", system-ui;
    } /* 3番目 */
  }
  &--lead{
    height: auto;
    min-height: 3rem;
    // max-height: 70rem;
    line-height: 7rem;
    font-weight: 500;
    font-size: 2.6rem;
    white-space: pre-wrap;
    line-break: strict;
    word-wrap: break-word;
    line-height: 1.3;
    letter-spacing: 2px;
    @media (min-width: 599px) {
      font-size: 16px;
    }
  }
  &--body{
    height: auto;
    min-height: 3rem;
    // max-height: 70rem;
    line-height: 7rem;
    font-size: 2.6rem;
    white-space: pre-wrap;
    line-break: strict;
    word-wrap: break-word;
    line-height: 1.3;
    letter-spacing: 2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* 任意の行数を指定 */

    @media (min-width: 599px) {
      font-size: 16px;
    }
  }
  &--body:empty,
  &--days:empty {
      display: none;
  }
  &--imgbox{
    display: flex;
    flex-wrap: wrap;
    width: 100%; /* Change this */
    height: auto; /* And this */
    border-radius: 3rem;
    border: 3px solid #10AE33;
    overflow: hidden;
    background-color: #D9D9D9;
  }
  // &--imgbox.images-1 {
  //   // grid-template-columns: 1fr;
  //   grid-template-columns: repeat(1, 560px);
  //     grid-template-rows: 560px;
  //     overflow: hidden;
  //     @media (max-width: 599px) {
  //       grid-template-columns: repeat(1, 93rem);
  //       grid-template-rows: 93rem;
  //     }
  // }

  // &--imgbox.images-2 {
  //   grid-template-columns: repeat(2, 280px);
  //     grid-template-rows: 280px;
  //     overflow: hidden;
  //     @media (max-width: 599px) {
  //       grid-template-columns: repeat(2, 46rem);
  //       grid-template-rows: 46rem;
  //     }
  // }

  // &--imgbox.images-3 {
  //   // grid-template-columns: 1fr 1fr;
  //   // grid-template-rows: 1fr 2fr;
  //   grid-template-columns: repeat(2, 280px);
  //     grid-template-rows: 276px 280px;
  //     overflow: hidden;
  //     grid-template-areas:
  //       "one two"
  //       "three two";
  //       @media (max-width: 599px) {
  //         grid-template-columns: repeat(2, 46rem);
  //         grid-template-rows: 46rem 46rem;
  //     }
  // }

  // &--imgbox.images-4 {
  //   // grid-template-columns: 1fr 1fr;
  //   // grid-template-rows: 1fr 1fr;
  //   grid-template-columns: repeat(2, 280px);
  //   grid-template-rows: repeat(2, 280px);
  //   overflow: hidden;
  //   @media (max-width: 599px) {
  //     grid-template-columns: repeat(2, 46rem);
  //     grid-template-rows: repeat(2, 46rem);
  //   }
  // }
  ul:not(:has(img)) {
      border: none;
  }
  &--imgpin{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 50%;
    border: 1px solid black;
    padding: 10px;
    background-color: #D9D9D9;
    transform: scale(1.01);
    // @media (max-width: 599px) {
    //   height: 76rem;
    // }
    // min-width: 50%;
    // max-width: 100%;
    // text-align: center;
    // min-height: 38rem;
    // max-height: 38rem;
  }
  &--status{
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 3;
    // position: relative;
    @media (max-width: 599px) {
      font-size: 10px;
    }
    button{
      display: flex;
      align-items: center;
      // width: 30px;
    }
    &--tags{
      position: absolute;
      top: -22px;
    }
    &--days{
      display: flex;
      align-items: center;
      .smallicon{
        width: 16px;
        margin-right: 5px;
      }
    }
  }
  &--imgsize{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 0;
  }
}
.post--imgbox.images-3 .post--imgpin:nth-child(3) {
  grid-area: three;
  grid-column: span 2; /* 3枚目の画像が2列を占めるようにします */
}

.content.post--img {
  display: flex;
  justify-content: center;
}

  button{
    text-align: end;
    .smallicon{
        // width: 50%;
        width: 15px;
      }
  }
  .trip{
    position: absolute;
    bottom: 12%;
    right: 17%;
    @media (max-width: 599px) {
      margin-right: 65px;
      margin-bottom: 5px;
      bottom: 9%;
      right: 6%;
    }
  }

  .sortbox{
    position: absolute;
    bottom: 12%;
    right: 6%;
    .sort{
      position: relative;
      bottom: 22%;
      right: -25%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      @media (max-width: 599px) {
        bottom: 9%;
        left: 6%;
        right: 100%;
      }
      #text{
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .sort-buttons{
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    bottom: -100%;
    right: 6%;
    &.open{
      display: flex;
    }
    .sort-button{
      width: 54px;
      height: 28px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .searched{
    position: absolute;
    bottom: 40%;
    right: 4%;
    img{
      width: 50%;
    }
    @media (max-width: 599px) {
      bottom: 7%;
      right: unset;
      top: 105px;
      left: 80px;
      img{
        width: 50px;
      }
    }
  }

  .point-button{
      // width: 10rem;
      // height: 6rem;
      // background-color: gray;
      display: flex;
      align-items: center;
      .smallicon{
      // width: 30%;
      width: 16px;
      }
      span{
          padding-left: 6px;
      }
  }

  .pointModal{
    .enter{
        // width: 26px;
        width: 100%!important;
      }
    p{
    display: flex;
    justify-content: flex-start;
    align-items: center;
      .smallicon{
      // width: 30%;
      width: 26px;
      // cursor: pointer;
      }
      span{
        padding-left: 10px;
      }
    }
  .backbtn{
    position: relative;
    top: 0px;
    left: 92%;
    width: 30px;
    height: 5px;
    img{
      cursor: pointer;
      width: 30px;
    }
  }

  .pointadd{
    gap: 30px;
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3{
      font-size: 20px;
      line-height: 1.5;
      text-align: center;
    }
    &--postlx{
      height: auto;
      p{
        img{
          padding: 6px;
        }
        width: 250px;
        height: 50px;
        background: #323232;
        border-radius: 5px;
      }
    }
    &--addlx{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      height: 50px;
      background: #323232;
      border-radius: 5px;
      input{
        width: 54%;
        background: #D9D9D9;
        color: #00A8A8;
        padding: 6px 0 6px 6px;
        margin: 0 0 0 6px;
        border-radius: 3px;
      }
      button{
        width: 46%;
        padding:0 6px;
      }
    }
    &--mylx{
      p{
        display: flex;
        justify-content: space-between;
        button{
          width: 45%;
        }
        img{
          width: 100%;
          padding: 6px;
        }
        width: 250px;
        height: 50px;
        background: #323232;
        border-radius: 5px;
      }
    }
    // .supportadd{
    //   display: flex;
    //   input{
    //     width: 40%;
    //     padding: 6px;
    //   }
    // }
    // p{
    //   height: 30px;
    // }
    // .supportadd{
    //   height: 30px;
    // }
  }
  
  }

  .pointModal {
      display: none;
  }

  .point-button {
  position: relative;
}

  .pointModal {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 9999;
  }

  // .commentModal.open{
  //   top:-440%;
  // }
  
  .pointModal.open {
    display: block;
    position: absolute;
    z-index: 99999;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    // background-color: rgba(128, 128, 128, 0.308);
    background-color: #000000ad;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    .pointModalBody{
      background-color: #D9D9D9;
      width: 400px;
      height:auto;
      padding: 20px;
      border-radius: 10px ;
      // color: #323232;
      color: #fff;
      line-height: 1.5px;
      background: linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;
    }
  }

  
  .logo-icon{
    position: absolute;
    left: 29px;
    top: 30%;
    width: 69px;
    @media (max-width: 599px) {
      top: 2%;
      width: 59px;
      left: 50%; 
      transform: translateX(-50%); 
    }
  }
  .post--status {
    font-size :12px;
    white-space: nowrap;
    .selectbutton{
      .editicon {
        background: linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;
        width: 64px;
        padding: 0 7px;
        display: block;
        line-height: 2;
        margin: 5px 0px;
        position: relative; /* ボタンに対して相対位置を設定 */
        @media (max-width: 599px) {
          width: 55px;
        }
        &::before { /* ::beforeを使用して内容の前に画像を挿入 */
          content: ""; /* 必須。内容が空でもこのプロパティが必要 */
          position: absolute; /* 絶対位置指定でボタン内に配置 */
          top: 50%; /* 上端から50%の位置に配置し、 */
          left: 5px; /* 左端から-30pxの位置に配置してボタンの外に出る */
          transform: translateY(-50%); /* Y軸方向に-50%移動して中央に来るよう調整 */
          width: 20px; /* 画像の幅 */
          height: 20px; /* 画像の高さ */
          background: url("../../assets/editicon.png") no-repeat center center; /* 画像のパスと表示設定 */
          background-size: contain; /* 背景画像を要素の大きさに合わせて調整 */
          @media (max-width: 599px) {
            width: 12px;
            left: 8px;
          }
        }
      }
      .rembicon{
        background: linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;
        width: 64px;
        padding: 0 7px;
        display: block;
        line-height: 2;
        margin: 5px 0px;
        position: relative; /* ボタンに対して相対位置を設定 */
        @media (max-width: 599px) {
          width: 55px;
        }
        &::before { /* ::beforeを使用して内容の前に画像を挿入 */
          content: ""; /* 必須。内容が空でもこのプロパティが必要 */
          position: absolute; /* 絶対位置指定でボタン内に配置 */
          top: 50%; /* 上端から50%の位置に配置し、 */
          left: 5px; /* 左端から-30pxの位置に配置してボタンの外に出る */
          transform: translateY(-50%); /* Y軸方向に-50%移動して中央に来るよう調整 */
          width: 20px; /* 画像の幅 */
          height: 20px; /* 画像の高さ */
          background: url("../../assets/rembicon.png") no-repeat center center; /* 画像のパスと表示設定 */
          background-size: contain; /* 背景画像を要素の大きさに合わせて調整 */
          @media (max-width: 599px) {
            width: 12px;
            left: 8px;
          }
        }
      }
    }
  }
</style>