<template>
    <div v-if="isOpen" class="search-modal">
        <div class="search-modal--box">
            <button @click="closeModal">
                <img src="../../assets/backbtn.png" alt="" class="smallicon">
            </button>
            <p>検索するタグを入力</p>
            <input v-model="searchText" type="text">
            <form action="#">
                <label for="checkbox"></label>
                <input type="checkbox" id="checkbox" name="checkbox" v-model="searchOptions.date">
                <span>指定した日付で検索</span><br>

                <div v-if="searchOptions.date">
                    <datepicker v-model="selectedDate" :value-format="valueFormat"></datepicker>
                </div>
            </form>

            <router-link :to="generateSearchRoute" @click="search" class="searchbtn">
                <img src="../../assets/search-w.png" alt="" class="searchbtn-img">
                検 索
            </router-link>
        </div>
    </div>
</template>
  
<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { format } from 'date-fns';
import Datepicker from 'vue3-datepicker';

export default {
    components: {
        Datepicker
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const router = useRouter();
        const route = useRouter();
        // 日付関係の値
        const valueFormat = 'yyyy-MM-dd';
        const selectedDate = ref(new Date());
        // 入力テキストの値
        const searchText = ref('');
        // 検索オプション
        const searchOptions = ref({
            date: false,
        });

        const closeModal = () => {
            // 親コンポーネントにイベントを発火し、isOpenプロパティを更新する
            emit('update:isOpen', false);
        };

        const search = () => {

            const searchParams = [];
            if (searchOptions.value.date) {
                searchParams.push(format(selectedDate.value, valueFormat));
            }
            // searchText.value も追加する
            if (searchText.value) {
                searchParams.push(searchText.value);
            }

            // ローカルストレージにsearchParamsを保存する
            localStorage.setItem('searchParams', JSON.stringify(searchParams));

            //console.log('検索パラメータ:', searchParams);

            searchText.value = '';
            emit('update:isOpen', false);

            // Vue Routerから現在のパスを取得
            const currentPath = router.currentRoute.value.path;

            //console.log('遷移しまーす');
            // もし現在のパスが/searchであればページをリロード
            if (currentPath === '/noauthnotextsearch' && !searchText.value.trim()) {
                //console.log('サーチページですよ');
                // location.reload();
            }
        };

        // 検索ルートの生成
        const generateSearchRoute = computed(() => {
            // searchText の値が空の場合、空のパスを返す
            if (!searchText.value.trim()) {
                emit('update:isOpen', false);
                //console.log('テキストから');
                return '/noauthnotextsearch';
            }
            //console.log('テキストある','/noauthsearch/' + searchText.value);
            return '/noauthsearch/' + searchText.value;
        });

        return {
            searchText,
            selectedDate,
            valueFormat,
            closeModal,
            search,
            generateSearchRoute,
            searchOptions
        };
    }
};
</script>



<style lang="scss" scoped>

    input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #000;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background: #D9D9D9;
        color: #10AE33;
        margin: 5px 0;
        margin-right: 15px;
    }

    /* チェックボックスがチェックされたときのスタイル */
    input[type="checkbox"]:checked::before {
        content: "\2713"; /* チェックマークのUnicode */
        display: block;
        text-align: center;
        background: #10AE33;
        color: #D9D9D9;
        font-weight: bold;
        line-height: 18px; /* チェックボックスの高さと同じ */
    }


    input[type="text"] {
        font-size: 20px;
        background: #D9D9D9;
        color: #323232;
        line-height: 1.5;
        padding: 5px;
    }

/* スタイルをここに追加 */
.search-modal {
        /* 適切なスタイルを追加 */
        display: block;
        position: absolute;
        z-index: 99999;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        height: 100%;
        // background-color: rgba(128, 128, 128, 0.308);
        background-color: #000000ad;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3rem;
        &--box{
            width: 517px;
            @media (max-width: 599px) {
                width: 100%;
            }
            height: auto;
            background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
            color: #D9D9D9;
            padding: 20px;
            border-radius: 2rem;
            input.text{
                width: 100%;
                background: #D9D9D9;
                color: #323232;
                line-height: 1.5;
                padding: 5px;
            }
            input.time{
                font-size: 20px;
                background: #D9D9D9;
                color: #323232;
                line-height: 1.5;
                padding: 5px;
            }
            input{
                border: 1px solid black;
            }
        }
        button{
            width: 100%;
            text-align: end;
            .smallicon{
            }
        }
        .searchbtn{
            width: 107px;
            height: 37px;
            display: block;
            background: linear-gradient(180deg, rgba(25, 3, 3, 0.1) 3.32%, #323232 94.3%), #00A8A8;
            text-align: center;
            line-height: 2;
            margin: 10px auto 0px;
            font-size: 16px;
            &-img{
                width: 28%;
            }
        }
    }
    .v3dp__datepicker{
        height: auto;
        // background:linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;;
        width: 60%;
        background: #D9D9D9;
        color: #323232;
        line-height: 1.5;
        padding: 5px;
        input.time {
            width: 100%;
        }       
    }
</style>
  
