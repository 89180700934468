<template>
    <div class="load" :class="{ 'off': isOff }">
        <img src="../../assets/iconorigin.png" alt="">
    </div>
</template>

<script>
    export default {
        data() {
        return {
            isOff: false,
        };
        },
        mounted() {
        setTimeout(() => {
            this.isOff = true;
        }, 1000); // 1000ミリ秒（＝1秒）後に isOff を true に設定
        },
    };
</script>

<style lang="scss" scoped>
.load {
    background: linear-gradient(153deg, rgba(238, 238, 238, 0.13) 3.32%, rgba(32, 32, 32, 0) 94.3%), #323232;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;
    opacity: 1;
}

.off {
    opacity: 0; 
    pointer-events: none;
}
</style>